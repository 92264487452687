<template>
	<div class="patient_chart add_local_files">
		<div class="row patient_chart add_local_files">
			<div class="col-lg-5 edit_record_page tplate_edit_from">
				<div>
					<dv class="containt_detail_title">{{ this.pageTitle }} </dv>

					<div class="box-body containt_detail">
						<div class="row pd-bottom">
							<label for="text" class="col-sm-5 col-form-label right-bor">Selected Template Description<small class="asterisksign">*</small></label>
							<div class="col-sm-7">
								<label for="text" class="col-form-label fs-18 fw-600 cursor-text">{{ form.title }}</label>
							</div>
						</div>
						<div class="row pd-bottom">
							<label for="text" class="col-sm-5 col-form-label right-bor">Module<small class="asterisksign">*</small></label>
							<div class="col-sm-7">
								<CustomDropDown :options="moduleList" :initialValue="form.module" fieldName="module"
									@item-selected="handleItemSelected" isDisabled="true"></CustomDropDown>
								<ValidationErrorMessageList :errors="v$.form.module.$errors" />
							</div>
						</div>
						<div class="row pd-bottom">
							<label for="text" class="col-sm-5 col-form-label right-bor">Service<small
									class="asterisksign">*</small></label>
							<div class="col-sm-7">
								<CustomDropDown :options="serviceList" :initialValue="form.service" fieldName="service"
									@item-selected="handleItemSelected"></CustomDropDown>
								<ValidationErrorMessageList :errors="v$.form.service.$errors" />
							</div>
						</div>
						<div class="row pd-bottom">
							<label for="text" class="col-sm-5 col-form-label right-bor">Demographic<small class="asterisksign">*</small></label>
							<div class="col-sm-7">
								<div class="cunsult-demographic-provider position-relative">
									<div class="search">
										<img class="serch_icon" src="/images/search-icon.svg" alt="">
										<input type="text" @keyup="patientSearch" class="form-control w-100"
											placeholder="Search Patient" v-model="patient_search" ref="globalSearch">
									</div>
									<div class="search_drop_box patientlist-demographic" v-if="patientList.length > 0">
										<div class="item_patient-demographic">
											<slot v-for="(item, index) in patientList" :key="index">
												<div class="con_drop_line" @click="selectPatient(item.patient_id, item.last_name + ', ' + item.first_name)">
													<div class="row px-2 align-items-center">
														<div class="col-md-6 px-2">
															<div class="man_haeding">
																{{ item.last_name }}, {{ item.first_name }}
																<span>({{ item.gender }})</span>
															</div>
															<ul>
																<li>
																	DOB: {{ item.dob }}
																</li>
																<li>
																	HIN: {{ item.health_insurance_no }}
																</li>
																<li>
																	Tel: {{ item.cell_phone }}
																</li>
															</ul>
														</div>
														<div class="col-md-6 px-2 text-end">
															<ol class="">
																<li class="prevent">
																	<router-link
																		:to="{ name: 'PatientChart', params: { patient_id: item.patient_id, } }"
																		target="_blank">M</router-link>
																</li>
																<li class="prevent">
																	<router-link
																		:to="{ name: 'PatientEChart', params: { patient_id: item.patient_id, } }"
																		target="_blank">E</router-link>
																</li>
																<li class="prevent">
																	<router-link
																		:to="{ name: 'PatientEChartPrescriptionList', params: { patient_id: item.patient_id, } }"
																		target="_blank">Rx</router-link>
																</li>
																<li class="prevent">
																	<router-link
																		:to="{ name: 'PatientEChartPrescriptionList', params: { patient_id: item.patient_id, } }"
																		target="_blank">Label</router-link>
																</li>
															</ol>
														</div>
													</div>
												</div>
											</slot>
										</div>
										<div class="create_grpc border-top"><router-link target="_blank" to="/patient/create">CREATE DEMOGRAPHIC</router-link></div>
									</div>
									<ValidationErrorMessageList :errors="v$.form.patient_id.$errors" />
								</div>
							</div>
						</div>

						<div class="row pd-bottom">
							<label for="text" class="col-sm-5 col-form-label right-bor">Referring Practitioner<small class="asterisksign">*</small></label>
							<div class="col-sm-7">
								<CustomDropDown :options="referringProviderList" :initialValue="form.provider_name"
									fieldName="referring_practitioner_id" @item-selected="handleItemSelected">
								</CustomDropDown>
								<ValidationErrorMessageList :errors="v$.form.referring_practitioner_id.$errors" />
							</div>
						</div>

						<!-- <div class="row pd-bottom">
						<label for="text" class="col-sm-5 col-form-label right-bor">Notes</label>
						<div class="col-sm-7"><input type="text" class="form-control" v-model="form.additional_info"></div>
					</div> -->

						<label for="text" class="col-sm-5 col-form-label popup_link cursor-text mt-30"
							v-if="this.$route.params.module == 'consultation'">Fax</label>
						<div class="row pd-bottom" v-if="this.$route.params.module == 'consultation'">
							<label for="text" class="col-sm-5 col-form-label right-bor">Fax Subject</label>
							<div class="col-sm-7"><input type="text" v-model="form.fax_subject" class="form-control">
							</div>
						</div>

						<div class="row pd-bottom pb-0" v-if="this.$route.params.module == 'consultation'">
							<label for="text" class="col-sm-5 col-form-label right-bor">Specialist<small
									class="asterisksign">*</small></label>
							<div class="col-sm-7">
								<div class="specialist-DropDown-pre">
									<CustomDropDown :options="specialist_option" :initialValue="specialist_text"
										@item-selected="handleItemSelected" fieldName="specialist_id">
									</CustomDropDown>
									<ValidationErrorMessageList :errors="v$.form.specialist_id.$errors" />
								</div>
							</div>
						</div>

						<!-- <div class="or_line"><span>OR</span></div> -->
						<!-- <div style="margin: 0 calc(var(--scale-ratio) * 24px) 0 0px;">
							<div class="custom-form">
								<div class="input_box">
									<input type="text" id="typeunique_idX-2" class="form-control form-control-lg" required
										v-model="form.fax_no">
									<label class="form-label" for="typeunique_idX-2">Recipient Fax Number</label>
								</div>
							</div>
						</div> -->
					</div>
				</div>

				<div class="btn_part lab-btn-part text-center">
					<button class="comman_brdr_btn big_btn mx36" @click="backToList"> Cancel </button>
					<button class="comman_btn big_btn mx36" @click="uploadDocument">Save <img src="images/loader.gif"
							v-if="savingLoader" style="width: calc(var(--scale-ratio) * 18px);" /></button>
					<button class="comman_btn big_btn mx36" @click="uploadDocument('save_fax')">
						<span v-if="this.$route.params.module == 'consultation'">Save & Fax</span>
						<span v-else>Save & Print</span>
						<img src="images/loader.gif" v-if="savingFaxLoader"
							style="width: calc(var(--scale-ratio) * 18px);">
					</button>
				</div>
			</div>
			<div class="col-lg-7">
				<div class="slide_deatil_box imaging_template_pdf overflow-auto" ref="pdfContainer"
					@scroll="handleScroll($event)">
					<div class="text-center position-relative" v-if="documentPath"
						style="width: 100%; height: 100%; margin: 0 auto;">
						<div id="renderAllCanvasPages" ref="pdfCanvas" style="width: 100%; height: 100%;">
							<div class="pdf-view">
								<div v-for="textBox in renderAllTextBoxes" :key="textBox.id">
									<div class="text-box position-absolute" :ref="`textBox-${textBox.id}`"
										v-if="textBox.hasEmptyVal" :style="{
										left: `${textBox.x_loc}px`,
										top: `${textBox.y_loc}px`,
										width: `${textBox.width}px`,
										height: `${textBox.height}px`,
										// display: textBox.pageNum === currentPage ? 'block' : 'none',
										fontSize: `${textBox.fontSize}px`,
									}">
										<textarea class="text-box-content" :ref="`textarea-${textBox.id}`" :style="{
												boxSizing: 'border-box',
												width: `${textBox.width}px`,
												height: `${textBox.height}px`,
												fontSize: `${textBox.fontSize}px`,
												display: 'block',
												left: `${textBox.x_loc}px`,
												top: `${textBox.y_loc}px`,
												backgroundColor: 'rgb(229 232 243)',
												border: '0px',
												outline: 'none',
												resize: 'none',
												verticalAlign: 'top',
											}" v-model="textBox.placeholder" @input="handleInputChange(textBox)"></textarea>
									</div>
								</div>
								<div v-for="(dropDown, index) in renderAllDropDowns" :key="index">
									<div class="pdf-dropdown-drag position-absolute" :ref="`dropdown-${index}`" :style="{
										left: `${dropDown.x_loc}px`,
										top: `${dropDown.y_loc}px`,
										width: `${dropDown.width}px`,
										height: `${dropDown.height}px`,
										fontSize: (dropDown.fontSize) + 'px',
									}">
										<div
											class="name-selcet-w-pdf d-flex justify-content-center align-items-center h-100">
											<div class="custom-select position-relative" @click="toggleDropdown(index)">
												<div class="select-display d-flex justify-content-between align-items-center"
													:style="{
														height: `${dropDown.height}px`,
														width: `${dropDown.width}px`,
													}">
													<span class="selected-list-actv" :style="{
														fontSize: (dropDown.fontSize) + 'px',
													}">{{ dropDown.placeholder }}</span>
													<img src="/images/down-arrow-new.svg" class="arrow open rotate180">
												</div>
												<ul class="select-options" v-if="dropDown.isOpen">
													<li v-for="(option, optionIndex) in dropDown.database_tag"
														:key="optionIndex" class="is-selected" :value="option" :style="{
															fontSize: (dropDown.fontSize) + 'px'
														}" @click="selectOption(index, optionIndex)">
														{{ option }}
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div v-for="(checkBox, index) in renderAllCheckBoxes" :key="index">
									<div class="checkbox-box position-absolute" :style="{
										left: checkBox.x_loc + 'px',
										top: checkBox.y_loc + 'px',
										width: (checkBox.width) + 'px',
										height: (checkBox.height) + 'px',
									}">
										<div class="pdf-checkbox">
											<input type="checkbox" :checked="checkBox.placeholder"
												@change="toggleCheckbox(index)" class="form-check-input m-0" :style="{
													height: (checkBox.height) + 'px',
													width: (checkBox.width) + 'px',
												}" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import CustomDropDown from '../../base/formFields/CustomDropDown.vue';
import axios from "@/scripts/axios.js";
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import $ from "jquery";
import ValidationErrorMessageList from '../../base/ValidationErrorMessageList.vue';

export default {
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			form: {
				title: null,
				// additional_info: null,
				module: null,
				dynamicFields: [],
				referringProviderList: [],
				specialist_text:'Select',
				pageTitle:null
			},
			moduleList: [
				{ value: "Consultation", title: "Consultation" },
				{ value: "Imaging", title: "Imaging" },
				{ value: "Lab", title: "Lab" },
				{ value: "Others", title: "Others" },
			],
			documentPath: null,
			savingLoader: false,
			savingFaxLoader: false,
			patientList: [],
			serviceList: [],
			imagingServiceList: [
				{ value: "Bone Density Scan", title: "Bone Density Scan" },
				{ value: "CT Scan", title: "CT Scan" },
				{ value: "MRI", title: "MRI" },
				{ value: "Mammography", title: "Mammography" },
				{ value: "Ultrasound", title: "Ultrasound" },
				{ value: "X-Ray", title: "X-Ray" },
				{ value: "Others", title: "Others" },
			],
			consultationServiceList: [
				{ value: "Cardology", title: "Cardology" },
			],
			labServiceList: [
				{ value: "Blood Work", title: "Blood Work" },
				{ value: "Microbiology", title: "Microbiology" },
				{ value: "Urine", title: "Urine" },
				{ value: "Others", title: "Others" },
			],
			patient_detail: [],
			specialist_option: [],

			existingPdfBytes: null,
			pdfDoc: null,
			totalPages: 0,
			currentPage: 1,
			isShowPagination: false,

			annotations: '',
			annotatedPdfBytes: new Uint8Array(),
			allTextBoxes: [],
			allDropDowns: [],
			allImages: [],
			allSignatures: [],
			allCheckBoxes: [],
			helveticaFont: null,
			selectedService: null,

			renderAllTextBoxes: [],
			renderAllDropDowns: [],
			renderAllImages: [],
			renderAllSignatures: [],
			renderAllCheckBoxes: [],

			savePdfDoc: null,
			onSaveExistingPdfBytes : null,

			selectedTextBox: {},
			selectedTextBoxId: null,
			selectedElement: '',
			selectedElementId: null,

			maxFontSize: 18,
			minFontSize: 12,

			isProcessing: false,

			pageOffsets: [],

			defaultCanvasWidth: null,
			defaultCanvasHeight: null,

			pdfRenderScale: null,
			annotationPdfScale: null,
			pdfContainerPadding: 10,
			pdfContainerWidth: null,
			removeLeftSpace: null,
		}
	},
	components: {
		CustomDropDown,
		ValidationErrorMessageList
	},
	validations() {
		const baseValidations = {
			module: {
				required: helpers.withMessage("Please select module.", required),
			},
			service: {
				required: helpers.withMessage("Please select service.", required),
			},
			referring_practitioner_id: {
				required: helpers.withMessage("Please select referring practitioner.", required),
			},
			patient_id: {
				required: helpers.withMessage("Please select demographic.", required),
			}
		};

		if (this.$route.params.module === 'consultation') {
			return {
				form: {
					...baseValidations,
					specialist_id: {
						required: helpers.withMessage("Please select specialist.", required),
					}
				}
			};
		} else {
			return {
				form: {
					...baseValidations
				}
			};
		}
	},
	methods: {
		handleScroll(event) {
			const pdfContainer = event.target;
			const scrollTop = pdfContainer.scrollTop;

			const currentPage = this.pageOffsets.find(({ offsetTop, height }) =>
				scrollTop >= offsetTop && scrollTop < offsetTop + height
			);

			if (currentPage) {
				this.currentPage = currentPage.pageNum;
			}
		},
		handleInputChange(textBox) {
			const minFontSize = 12;
			const maxFontSize = 16;

			// Create a canvas element to measure the text width
			const canvas = document.createElement('canvas');
			const context = canvas.getContext('2d');
			context.font = `${textBox.fontSize}px Inter`; // Set the initial font style

			// Measure the text width
			let textWidth = context.measureText(textBox.placeholder).width;

			// Adjust font size if text exceeds the text box width
			while (textWidth > textBox.width && textBox.fontSize > minFontSize) {
				// Calculate the new font size ensuring it doesn't go below minFontSize
				textBox.fontSize = Math.max(textBox.fontSize - 1.5, minFontSize);
				context.font = `${textBox.fontSize}px Inter`; // Update the font style

				// Measure the text width again
				textWidth = context.measureText(textBox.placeholder).width;
			}

			// Check if the text width is less than the text box width
			while (textWidth < textBox.width && textBox.fontSize < maxFontSize) {
				// Increase font size
				textBox.fontSize = Math.min(textBox.fontSize + 1.5, maxFontSize);
				context.font = `${textBox.fontSize}px Inter`; // Update the font style

				// Measure the text width again
				textWidth = context.measureText(textBox.placeholder).width;

				// Stop if textWidth exceeds the text box width after increase
				if (textWidth > textBox.width) {
					textBox.fontSize = Math.max(textBox.fontSize - 1.5, minFontSize); // Adjust back if it exceeds
					break;
				}
			}

			// Find the matching textBox in allTextBoxes and update it
			const allTextBox = this.allTextBoxes.find(item => item.id === textBox.id);
			if (allTextBox) {
				allTextBox.placeholder = textBox.placeholder;
				allTextBox.fontSize = textBox.fontSize;
			}
		},
		// handleInputChange(textBox) {
		// 	const fixedFontSize = 12;

		// 	textBox.fontSize = fixedFontSize;
		// 	const allTextBox = this.allTextBoxes.find(item => item.id === textBox.id);
		// 	if (allTextBox) {
		// 		allTextBox.placeholder = textBox.placeholder;
		// 		allTextBox.fontSize = textBox.fontSize;
		// 	}
		// },
		async edit() {
			if (this.$route.name === 'editModule') {
				let url = '';
				if (this.$route.params.module === 'imaging') {
					url = 'patient/imaging/retrieve';
				} else if (this.$route.params.module === 'consultation') {
					url = 'patient/consultations/retrieve';
				} else if (this.$route.params.module === 'lab') {
					url = 'patient/lab/retrieve';
				}

				try {
					const response = await axios.post(url, { id: this.$route.params.id, patient_id: this.$route.params.patient_id });
					if (response.data.status === 200) {
						const detail = response.data.data;
						this.form = detail;
						this.form.title = detail.title;
						this.form.module = detail.module;
						this.documentPath = this.docFileUrl(detail.file_path);
						this.annotations = detail.formFields;
						// this.documentPath = this.documentUrl(detail.file_path);

						for (const key in this.annotations) {
							if (Object.prototype.hasOwnProperty.call(this.annotations, key)) {
								const annotation = this.annotations[key];

								if (typeof annotation === 'object' && annotation !== null) {
									annotation.id = key;

									if (key.includes('textBox')) {
										annotation.hasEmptyVal = !annotation.placeholder;
										this.allTextBoxes.push(annotation);

										// Clone the annotation object, then set isOpen on the clone
										const clonedAnnotation = JSON.parse(JSON.stringify(annotation));
										clonedAnnotation.hasEmptyVal = !annotation.placeholder;

										// Push the modified clone into renderAllDropDowns
										this.renderAllTextBoxes.push(clonedAnnotation);
									} else if (key.includes('dropDown')) {
										annotation.placeholder = '';
										annotation.selected_value = '';
										annotation.isOpen = false;
										this.allDropDowns.push(annotation);

										// Clone the annotation object, then set isOpen on the clone
										const clonedAnnotation = JSON.parse(JSON.stringify(annotation));
										clonedAnnotation.isOpen = annotation.isOpen;

										// Push the modified clone into renderAllDropDowns
										this.renderAllDropDowns.push(clonedAnnotation);
									} else if (key.includes('image')) {
										this.allImages.push(annotation);
										this.renderAllImages.push(JSON.parse(JSON.stringify(annotation)));
									} else if (key.includes('signature')) {
										this.allSignatures.push(annotation);
										this.renderAllSignatures.push(JSON.parse(JSON.stringify(annotation)));
									} else if (key.includes('checkBox')) {
										this.allCheckBoxes.push(annotation);
										this.renderAllCheckBoxes.push(JSON.parse(JSON.stringify(annotation)));
									}
								} else if (key === 'pdf_scale') {
									this.annotationPdfScale = this.annotations[key];
								} else {
									console.warn(`Unknown key type in annotation: ${key}`);
								}
							}
						}

						if (this.documentPath) {
							this.modifyPdf();
						}
						this.specialist_text = this.specialist_option.find(item => item.value === detail.specialist_id)?.title || 'Select';
					} else {
						this.$filters.moshaToast(response.data.message, 'error');
					}
				} catch (error) {
					this.$store.state.loader = false;
					this.$filters.moshaToast(error.message, 'error');
				}
			} else {
				try {
					const response = await axios.post('e-forms/retrieve', {
						id: this.$route.params.template_id,
						patient_id: this.$route.params.patient_id,
					});

					if (response.data.status === 200) {
						const { title, module, formFields, file_path } = response.data.data;
						this.form.title = title;
						this.form.module = module;
						this.dynamicFields = formFields;
						this.annotations = formFields;
						this.documentPath = this.documentUrl(file_path);

						for (const key in this.annotations) {
							if (Object.prototype.hasOwnProperty.call(this.annotations, key)) {
								const annotation = this.annotations[key];

								if (typeof annotation === 'object' && annotation !== null) {
									annotation.id = key;

									if (key.includes('textBox')) {
										annotation.hasEmptyVal = !annotation.placeholder;
										this.allTextBoxes.push(annotation);

										// Clone the annotation object, then set isOpen on the clone
										const clonedAnnotation = JSON.parse(JSON.stringify(annotation));
										clonedAnnotation.hasEmptyVal = !annotation.placeholder;

										// Push the modified clone into renderAllDropDowns
										this.renderAllTextBoxes.push(clonedAnnotation);
									} else if (key.includes('dropDown')) {
										annotation.placeholder = '';
										annotation.selected_value = '';
										annotation.isOpen = false;
										this.allDropDowns.push(annotation);

										// Clone the annotation object, then set isOpen on the clone
										const clonedAnnotation = JSON.parse(JSON.stringify(annotation));
										clonedAnnotation.isOpen = annotation.isOpen;

										// Push the modified clone into renderAllDropDowns
										this.renderAllDropDowns.push(clonedAnnotation);
									} else if (key.includes('image')) {
										this.allImages.push(annotation);
										this.renderAllImages.push(JSON.parse(JSON.stringify(annotation)));
									} else if (key.includes('signature')) {
										this.allSignatures.push(annotation);
										this.renderAllSignatures.push(JSON.parse(JSON.stringify(annotation)));
									} else if (key.includes('checkBox')) {
										this.allCheckBoxes.push(annotation);
										this.renderAllCheckBoxes.push(JSON.parse(JSON.stringify(annotation)));
									}
								} else if (key === 'pdf_scale') {
									this.annotationPdfScale = this.annotations[key];
								} else {
									console.warn(`Unknown key type in annotation: ${key}`);
								}
							}
						}
						if (this.documentPath) {
							this.modifyPdf();
						}
					} else {
						this.$filters.moshaToast(response.data.message, 'error');
					}
				} catch (error) {
					this.$filters.moshaToast(error.message, 'error');
				}
			}
		},
		docFileUrl(documentPath){
			return `${process.env.VUE_APP_STORAGE_URL}/document/view/${documentPath}`;
		},
		documentUrl(documentPath) {
			// Assuming your documents are stored in the 'public' directory
			return `${process.env.VUE_APP_STORAGE_URL}/e-forms/document/view/${documentPath}`;
		},
		async modifyPdf() {
			try {
				if (!this.documentPath) {
					throw new Error('Document path is not defined.');
				}

				const pdfContainer = await this.$refs.pdfContainer;
				const pdfContainerEle = pdfContainer.getBoundingClientRect();
				this.pdfContainerWidth = pdfContainerEle.width;

				this.pdfRenderScale = pdfContainerEle.width / 612;
				console.log('this.pdfRenderScale:- ', this.pdfRenderScale);
				console.log('this.annotationPdfScale:- ', this.annotationPdfScale);

				// Use Promise.all to await all coordinate changes
				await Promise.all([
					this.coordinateChange('textBox'),
					this.coordinateChange('dropDownBox'),
					this.coordinateChange('imageBox'),
					this.coordinateChange('signatureBox'),
					this.coordinateChange('checkBox'),
				]);

				this.existingPdfBytes = await fetch(this.documentPath).then(res => res.arrayBuffer());

				this.pdfDoc = await PDFDocument.load(this.existingPdfBytes);

				this.totalPages = this.pdfDoc.getPageCount();
				this.helveticaFont = await this.pdfDoc.embedFont(StandardFonts.Helvetica);

				await this.drawAnnotations();
				await this.renderAllPages();

			} catch (error) {
				console.error('Error modifying PDF:', error);
			}
		},
		async coordinateChange(boxType = '') {
			let boxArray;

			switch (boxType) {
				case 'textBox':
					boxArray = this.renderAllTextBoxes;
					break;
				case 'dropDownBox':
					boxArray = this.renderAllDropDowns;
					break;
				case 'imageBox':
					boxArray = this.renderAllImages;
					break;
				case 'signatureBox':
					boxArray = this.renderAllSignatures;
					break;
				case 'checkBox':
					boxArray = this.renderAllCheckBoxes;
					break;
				default:
					console.warn(`Unknown box type: ${boxType}`);
					return;
			}

			boxArray.forEach((box) => {
				box.x_loc = (this.pdfRenderScale * box.x_loc) / this.annotationPdfScale;
				box.y_loc = (this.pdfRenderScale * box.y_loc) / this.annotationPdfScale;
				box.width = (this.pdfRenderScale * box.width) / this.annotationPdfScale;
				box.height = (this.pdfRenderScale * box.height) / this.annotationPdfScale;

				if (boxType === 'textBox' || boxType === 'dropDownBox') {
					box.fontSize = (this.pdfRenderScale * box.fontSize) / this.annotationPdfScale;
				}
			});
		},
		async getPdfPageScale(width) {
			width -= this.pdfContainerPadding * 2;
			const pdfPageWidth = 612;
			let scale = 1;
			const maxScale = 5;

			// Loop to find the appropriate scale
			while (scale <= maxScale) {
				const measureScaleWidth = pdfPageWidth * scale;

				if (measureScaleWidth >= width) {
					return Math.max(scale - 0.1, 1);
				}

				scale += 0.1;
			}

			return maxScale;
		},
		async renderAllPages() {
			try {
				await this.$nextTick(); // Ensure DOM is updated

				const pdfCanvas = this.$refs.pdfCanvas;
				if (!pdfCanvas) {
					console.error('PDF container reference not found.');
					return;
				}

				const annotatedPdfBytes = await this.pdfDoc.save();
				// await this.downloadPdf(annotatedPdfBytes);

				const loadingTask = window.pdfjsLib.getDocument({ data: annotatedPdfBytes });
				const pdf = await loadingTask.promise;

				this.pageOffsets = [];

				// Loop through each page in the PDF
				for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
					const page = await pdf.getPage(pageNum);

					const scale = this.pdfRenderScale;
					const viewport = page.getViewport({ scale });

					const outputScale = Math.max(window.devicePixelRatio, 1); // HiDPI support

					const pdfViewDiv = pdfCanvas.querySelector('.pdf-view');
					const canvas = document.createElement('canvas');
					const context = canvas.getContext('2d');

					canvas.width = Math.floor(viewport.width * outputScale);
					canvas.height = Math.floor(viewport.height * outputScale);
					// canvas.style.width = Math.floor(viewport.width) + 'px';
					// canvas.style.height = Math.floor(viewport.height) + 'px';
					canvas.style.width = '100%';

					const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

					// canvas.style.marginBottom = '10px';
					canvas.style.backgroundColor = '#fff';
					canvas.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.5)';
					// canvas.style.borderRadius = '5px';

					if (pageNum !== this.totalPages) {
						canvas.style.marginBottom = '10px';
						canvas.style.borderRadius = '5px';
					} else {
						canvas.style.borderBottomLeftRadius = '5px';
						canvas.style.borderBottomRightRadius = '5px';
					}

					const renderContext = {
						canvasContext: context,
						transform,
						viewport,
					};

					await page.render(renderContext).promise;
 
					pdfCanvas.insertBefore(canvas, pdfViewDiv);

					const offsetTop = canvas.offsetTop;
					const offsetBottom = offsetTop + canvas.offsetHeight;
					const offsetLeft = canvas.offsetLeft;
					const offsetRight = offsetLeft + canvas.offsetWidth;
					this.pageOffsets.push({
						pageNum,
						offsetTop,
						offsetBottom,
						offsetLeft,
						offsetRight,
						height: canvas.height,
						width: canvas.width,
					});
				}
				console.log('on render all pages - this.pageOffsets:- ', this.pageOffsets);

				this.$store.state.loader = false;
			} catch (error) {
				console.error('Error rendering all pages:', error);
			}
		},
		async drawAnnotations(from = '') {
			try {
				if (!this.helveticaFont) {
					console.error('Font not embedded. Embedding font now.');
					this.helveticaFont = await this.pdfDoc.embedFont(StandardFonts.TimesRoman);
				}

				for (let pageIndex = 0; pageIndex < this.totalPages; pageIndex++) {

					if (pageIndex < 0 || pageIndex >= (await this.pdfDoc.getPageCount())) {
						console.warn(`Invalid page number: ${pageIndex}, skipping...`);
						continue;
					}

					if (from == '') {
						const page = await this.pdfDoc.getPage(pageIndex);
						await Promise.all([
							await this.drawTextBoxes(page, pageIndex),
							await this.drawImageBoxes(page, pageIndex),
							await this.drawSignatureBoxes(page, pageIndex),
						]);
					}

					if (from === 'save') {
						const page = await this.savePdfDoc.getPage(pageIndex);
						await Promise.all([
							await this.drawTextBoxes(page, pageIndex),
							await this.drawImageBoxes(page, pageIndex, 'save'),
							await this.drawSignatureBoxes(page, pageIndex, 'save'),
							await this.drawDropDownBoxes(page, pageIndex),
							await this.drawCheckBoxes(page, pageIndex, 'save'),
						]);
					}
				}
			} catch (error) {
				console.error('Error drawing annotations on page:', error);
			}
		},
		// calculateAnnotationsPdfCoordinates(pdfPage, elementRect) {
		// 	const canvas = this.$refs.pdfCanvas;
		// 	const canvasRect = canvas.getBoundingClientRect();

		// 	const scaleX = pdfPage.getWidth() / canvasRect.width;
		// 	const scaleY = pdfPage.getHeight() / canvasRect.height;

		// 	const x = (elementRect.x_loc - canvasRect.left) * scaleX;
		// 	const y = pdfPage.getHeight() - ((elementRect.y_loc - canvasRect.top) * scaleY) - (elementRect.height * scaleY);

		// 	return { x, y, scaleX, scaleY };
		// },\
		// calculateAnnotationsPdfCoordinates(pdfPage, elementRect) {
		// 	const canvas = this.$refs.pdfCanvas;
		// 	console.log('canvas:- ', canvas);
		// 	const canvasRect = canvas.getBoundingClientRect();
		// 	console.log('canvasRect:- ', canvasRect);

		// 	// Calculate scaling factors between the canvas and the PDF dimensions
		// 	const scaleX = pdfPage.getWidth() / canvasRect.width;
		// 	const scaleY = pdfPage.getHeight() / canvasRect.height;

		// 	// Calculate the x position in the PDF coordinates
		// 	const x = elementRect.x_loc  * scaleX;

		// 	// Calculate the y position and flip it according to the PDF's coordinate system
		// 	const y = pdfPage.getHeight() - (elementRect.y_loc * scaleY) - (elementRect.height * scaleY);

		// 	return { x, y, scaleX, scaleY };
		// },

		// calculateAnnotationsPdfCoordinates(pdfPage, elementRect) {
		// 	// const canvas = this.$refs.pdfCanvas;
		// 	// const canvasRect = canvas.getBoundingClientRect();

		// 	// PDF's original page size (width = 612, height = 792)
		// 	const originalPdfWidth = 612;
		// 	const originalPdfHeight = 792;

		// 	// Get the rendering scale applied to the PDF (1.9 in your case)
		// 	const renderScale = 1.9;

		// 	// Calculate scaling factors between the PDF and canvas dimensions
		// 	const scaleX = (pdfPage.getWidth() / originalPdfWidth) * renderScale;
		// 	const scaleY = (pdfPage.getHeight() / originalPdfHeight) * renderScale;

		// 	// Calculate the x position in PDF coordinates
		// 	const x = Math.abs(pdfPage.getWidth() * (elementRect.x_loc - 19) / (pdfPage.getWidth() *  1.9));

		// 	// Adjust the y position to account for multiple pages and flip it
		// 	let y;
		// 	console.log('----------elementRect.onPage:- ', elementRect.pageNum);
		// 	if(elementRect.pageNum > 1) {
		// 		console.log('pdfPage.getHeight():- ', pdfPage.getHeight());
		// 		// y = Math.abs(((Math.abs(((elementRect.pageNum-1) * 1504 + ((elementRect.pageNum - 1) * 15)) - elementRect.y_loc) * pdfPage.getHeight()) / 1504) - pdfPage.getHeight());
		// 		y = Math.abs(Math.abs((pdfPage.getHeight() * (elementRect.y_loc + 40 - elementRect.offsetTop)) / (pdfPage.getHeight() * 1.9)) - pdfPage.getHeight());
		// 		// y = Math.abs(((pdfPage.getHeight() * Math.abs(elementRect.offsetTop + 15 - elementRect.y_loc)) / (pdfPage.getHeight() *  1.9)) - pdfPage.getHeight());
		// 	} else {
		// 		y = Math.abs(((pdfPage.getHeight() * (elementRect.y_loc)) / (pdfPage.getHeight() *  1.9)) - pdfPage.getHeight());
		// 	}

		// 	console.log(`Calculated coordinates: x = ${x}, y = ${y}`);

		// 	return { x, y, scaleX, scaleY };
		// },
		calculateAnnotationsPdfCoordinates(pdfPage, elementRect) {
			const originalPdfHeight = pdfPage.getHeight();
			const scalePdfHeight = pdfPage.getHeight() * this.annotationPdfScale;

			// Calculate the x position in PDF coordinates
			let x = null;
			if(elementRect.field_type.includes('image') || elementRect.field_type.includes('checkbox')) {
				x = Math.abs((elementRect.x_loc + 3) / this.annotationPdfScale);
			} else {
				x = Math.abs((elementRect.x_loc) / this.annotationPdfScale);
			}

			// Calculate the y position with page offsets applied
			let y = 0;
			if(elementRect.field_type.includes('image')) {
				y = (((elementRect.y_loc + 3) - elementRect.offsetTop) * ( originalPdfHeight / scalePdfHeight )) + (elementRect.height / (this.annotationPdfScale));
			} else {
				y = ((elementRect.y_loc - elementRect.offsetTop) * ( originalPdfHeight / scalePdfHeight )) + (elementRect.height / (this.annotationPdfScale));
			}

			// Flip the y-coordinate since PDF coordinates start from bottom-left
			y = pdfPage.getHeight() - y;
			
			console.log(`Calculated coordinates: x = ${x}, y = ${y}`);
			return { x, y };
		},
		async wrapText(text, maxWidth, initialFontSize) {
			const fontSize = initialFontSize; // Use initial font size directly
			let lines = [];
			
			// Function to calculate dimensions based on font size
			const calculateCharPerLine = (fontSize) => {
				// Calculate average characters per line based on the given font size
				return Math.floor(maxWidth / (fontSize * 0.5)); // Approximate char width ~ 0.5 * fontSize
			};

			const splitTextIntoLines = (text, charPerLine) => {
				const words = text.split(' ');
				let currentLine = "";

				words.forEach((word) => {
					const testLine = currentLine ? `${currentLine} ${word}` : word;
					if (testLine.length > charPerLine) {
						// If adding this word exceeds the character limit, push current line and start a new one
						if (currentLine) {
							lines.push(currentLine); // Push the current line to the array
						}
						currentLine = word; // Start a new line with the current word
					} else {
						currentLine = testLine; // Continue with the current line
					}
				});

				if (currentLine) lines.push(currentLine); // Push the last line if it exists
			};

			const charPerLine = calculateCharPerLine(fontSize); // Get characters per line
			splitTextIntoLines(text, charPerLine); // Split the text into lines

			return {
				fontSize: fontSize,
				lines: lines,
			};
		},
		getTextDimensions(text, fontSize) {
			const span = $("<span>").text(text).css({
				"fontSize": fontSize + "px",
				"position": "absolute",
				"visibility": "hidden",
				"display": "inline-block"
			});

			$("body").append(span);

			const width = span.width();
			const height = span.height();
			span.remove();

			return { width, height };
		},
		async drawTextBoxes(page, pageIndex) {
			Object.entries(this.allTextBoxes).forEach(([key, textBox]) => {
				const effectivePageIndex = textBox.pageNum - 1;
				if (effectivePageIndex === pageIndex) {
					const text = textBox.placeholder;
					if (!text) {
						return;
					}

					let { x, y } = this.calculateAnnotationsPdfCoordinates(page, textBox);
					
					if(textBox.database_tag != "") {
						let fontSize = textBox.fontSize;
						if(pageIndex > 0) {
							y -= (pageIndex * 1.5);
						} else {
							y -= 1.5;
						}
						page.drawText(textBox.placeholder, {
							x: x,
							y: y,
							size: fontSize,
							font: this.helveticaFont,
							color: rgb(0, 0.53, 0.71),
						});
						console.log(`database tag - Text box "${key}" added at (text: "${text}", x: ${x}, y: ${y}, fontSize: ${fontSize}) on page ${pageIndex}`);
					} else {
						let fontSize = textBox.fontSize;
						this.wrapText(textBox.placeholder, textBox.width, textBox.fontSize)
						.then(result => {
							const lines = result.lines;
							
							
							if(lines.length > 1) {
								fontSize = (result.fontSize / this.annotationPdfScale);
								const lineHeight = fontSize;
	
								// Start rendering from the top of the text box
								let startY = y + (lineHeight * lines.length) - (fontSize / this.annotationPdfScale);
								lines.forEach(line => {
									page.drawText(line, {
										x: x,
										y: startY,
										size: fontSize,
										font: this.helveticaFont,
										color: rgb(0, 0.53, 0.71),
									});
									startY -= lineHeight * 1.2;
								});
								console.log(`if - Text box "${key}" added at (text: "${text}", x: ${x}, y: ${y}, fontSize: ${fontSize}) on page ${pageIndex}`);
							} else {
								if(pageIndex > 0) {
									y -= (pageIndex * 1.5);
								}  else {
									y -= 1.5;
								}
								fontSize = result.fontSize / this.annotationPdfScale;
								lines.forEach(line => {
									page.drawText(line, {
										x: x + 3,
										y: y,
										size: fontSize,
										font: this.helveticaFont,
										color: rgb(0, 0.53, 0.71),
									});
								});
								console.log(`else - Text box "${key}" added at (text: "${text}", x: ${x}, y: ${y}, fontSize: ${result.fontSize}) on page ${pageIndex}`);
							}
						})
						.catch(error => {
							console.error('Error in wrapText:', error);
						});
					}
					// console.log(`Text box "${key}" added at (text: "${text}", x: ${x}, y: ${y}, fontSize: ${fontSize}) on page ${pageIndex}`);
				}
			});
		},
		async drawImageBoxes(page, pageIndex, from = '') {
			for (const [key, imageBox] of Object.entries(this.allImages)) {
				const effectivePageIndex = imageBox.pageNum - 1;

				if (effectivePageIndex === pageIndex) {
					const base64String = imageBox.placeholder.split(',')[1];
					const imageBytes = Uint8Array.from(atob(base64String), char => char.charCodeAt(0));

					let embeddedImage;
					if (imageBox.placeholder.includes('image/png')) {
						embeddedImage = from == 'save' ? await this.savePdfDoc.embedPng(imageBytes) : await this.pdfDoc.embedPng(imageBytes);
					} else if (imageBox.placeholder.includes('image/jpeg') || imageBox.placeholder.includes('image/jpg')) {
						embeddedImage = from == 'save' ? await this.savePdfDoc.embedJpg(imageBytes) : await this.pdfDoc.embedJpg(imageBytes);
					} else {
						console.error('Unsupported image format:', imageBox.placeholder);
						return;
					}

					let { x, y } = this.calculateAnnotationsPdfCoordinates(page, imageBox, pageIndex);

					const width = ((imageBox.width + 3) / this.annotationPdfScale);
					const height = ((imageBox.height + 3) / this.annotationPdfScale);

					if (isNaN(width) || isNaN(height)) {
						throw new Error(`Invalid dimensions: width=${width}, height=${height}`);
					}

					page.drawImage(embeddedImage, {
						x: x,
						y: y,
						width: width,
						height: height,
					});

					console.log(`Image box "${key}" added at (x: ${x}, y: ${y}) on page ${pageIndex}`);
				}
			}
		},
		async drawSignatureBoxes(page, pageIndex, from = '') {
			for (const [key, signatureBox] of Object.entries(this.allSignatures)) {
				try {
					const effectivePageIndex = signatureBox.pageNum - 1;
					if (effectivePageIndex === pageIndex) {
						const authUserRole = localStorage.getItem('authUserRole');
						const clinicId = localStorage.getItem('clinicId');
						const userId = signatureBox ? signatureBox.database_tag : localStorage.getItem('userId');

						if (!authUserRole || !clinicId || !userId) {
							throw new Error('Missing required parameters.');
						}

						let signatureSrc = '';
						if (authUserRole === 'Doctor' || authUserRole === 'Nurse') {
							if(signatureBox.placeholder == '') {
								const response = await axios.post('user/get-signature', { clinicId, userId });
	
								signatureSrc = response.data.data.base64_sign;
	
								if (!signatureSrc.startsWith('data:image/png;base64,')) {
									console.error('Invalid base64 signature format');
									continue;
								}
							} else {
								signatureSrc = signatureBox.placeholder;
							}

							const signatureData = signatureSrc.split(',')[1];
							const signatureBytes = Uint8Array.from(atob(signatureData), char => char.charCodeAt(0));

							let embeddedSignature;
							if (signatureSrc.includes('image/png')) {
								
								embeddedSignature = from === 'save' ? await this.savePdfDoc.embedPng(signatureBytes) : await this.pdfDoc.embedPng(signatureBytes);
							} else if (signatureSrc.includes('image/jpeg') || signatureSrc.includes('image/jpg')) {
								embeddedSignature = from === 'save' ? await this.savePdfDoc.embedJpg(signatureBytes) : await this.pdfDoc.embedJpg(signatureBytes);
							} else {
								console.error('Unsupported image format:', signatureSrc);
								continue;
							}

							if(from == 'save') {
								const currentPageIndex = signatureBox.pageNum - 1;
								if (currentPageIndex < 0 || currentPageIndex >= (await this.savePdfDoc.getPageCount())) {
									console.warn(`Invalid page number: ${signatureBox.pageNum}, skipping...`);
									continue;
								}							
							} else { 
								const currentPageIndex = signatureBox.pageNum - 1;
								if (currentPageIndex < 0 || currentPageIndex >= (await this.pdfDoc.getPageCount())) {
									console.warn(`Invalid page number: ${signatureBox.pageNum}, skipping...`);
									continue;
								}
							}

							let { x, y } = this.calculateAnnotationsPdfCoordinates(page, signatureBox, pageIndex);

							await page.drawImage(embeddedSignature, {
								x: x,
								y: y - 5,
								width: signatureBox.width / this.annotationPdfScale,
								height: signatureBox.height / this.annotationPdfScale,
							});

							console.log(`Signature "${key}" added at (x: ${x}, y: ${y}) on page ${signatureBox.pageNum}`);
						}
					}
				} catch (error) {
					console.error(`Error embedding signature for "${key}":`, error);
				}
			}
		},
		async drawDropDownBoxes(page, pageIndex) {
			Object.entries(this.allDropDowns).forEach(([key, dropDown]) => {
				const effectivePageIndex = dropDown.pageNum - 1;

				if (effectivePageIndex === pageIndex) {

					if (!this.helveticaFont) {
						console.error("Font is not loaded yet.");
						return;
					}

					if(dropDown.placeholder != '') {
						try {
							let { x, y } = this.calculateAnnotationsPdfCoordinates(page, dropDown);

							page.drawText(dropDown.placeholder, {
								x: x + ((dropDown.width / this.annotationPdfScale) / 3),
								y: y,
								size: dropDown.fontSize,
								font: this.helveticaFont,
								color: rgb(0, 0.53, 0.71),
							});
							console.log(`dropDown "${key}" added at (x: ${x}, y: ${y}) on page ${dropDown.pageNum}`);
						} catch (error) {
							console.error(`Error dropdown for "${key}":`, error);
						}
					}
				}
			});
		},
		async drawCheckBoxes(page, pageIndex, from = '') {
			Object.entries(this.allCheckBoxes).forEach(([key, checkBox]) => {
				const effectivePageIndex = checkBox.pageNum - 1;
				if (effectivePageIndex === pageIndex) {
					try {
						const checkboxName = checkBox.field_type + checkBox.id;

						const checkboxField = from == 'save' ? this.savePdfDoc.getForm().createCheckBox(checkboxName) : this.pdfDoc.getForm().createCheckBox(checkboxName);

						// checkBox.width -= 3;
						// checkBox.height -= 3;
						let { x, y } = this.calculateAnnotationsPdfCoordinates(page, checkBox, pageIndex);

						// Determine the maximum dimension for a square checkbox
						// const maxDimension = Math.min(checkBox.width, checkBox.height);
						const adjustedWidth = ((checkBox.width - 3) / this.annotationPdfScale);
						const adjustedHeight = ((checkBox.height - 3) / this.annotationPdfScale);

						checkboxField.addToPage(page, {
							x: x,
							y: y,
							width: adjustedWidth,
							height: adjustedHeight,
						});

						// Check the checkbox if `placeholder` is set to true
						if (checkBox.placeholder === true) {
							checkboxField.check();
						}

						console.log(`Checkbox "${key}" added at (x: ${x}, y: ${y}) on page ${checkBox.pageNum}`);
					} catch (error) {
						console.error(`Error checkbox for "${key}":`, error);
					}
				}
			});
		},
		toggleCheckbox(index) {
			this.allCheckBoxes[index].placeholder = !this.allCheckBoxes[index].placeholder;
		},
		selectOption(dropDownIndex, optionIndex) {
			const selectedOption = this.allDropDowns[dropDownIndex].database_tag[optionIndex];
			this.allDropDowns[dropDownIndex].placeholder = selectedOption;
			this.renderAllDropDowns[dropDownIndex].placeholder = selectedOption;
		},
		toggleDropdown(index) {
			this.renderAllDropDowns[index].isOpen = !this.renderAllDropDowns[index].isOpen;
		},
		handleClickOutside(event) {
			const globalSearchElement = this.$refs.globalSearch;
			if (globalSearchElement && !globalSearchElement.contains(event.target)) {
				this.patientList = [];
			}
		},
		handleItemSelected(obj) {
			if (obj.fieldName == 'module') {
				this.form.module = obj.item.value;
			} else if (obj.fieldName == 'service') {
				this.form.service = obj.item.value;
				this.specialist_text = 'Select'
				this.selectedService = obj.item.value;
				this.getSpecialistList();
			} else if (obj.fieldName == 'referring_practitioner_id') {
				this.form.referring_practitioner_id = obj.item.value;
			} else if (obj.fieldName == 'specialist_id') {
				this.form.specialist_id = obj.item.value;
				this.specialist_text = obj.item.title
			}
		},
		patientSearch() {
			if (this.patient_search.length > 2) {
				axios.post("json_autocomplete/patient-search", { search_text: this.patient_search })
					.then((response) => {
						this.patientList = response.data.data;
					})
			}
			if (this.patient_search.length == 0) {
				this.patientList = [];
				this.form.patient_id = null
			}
		},
		getConsultationsServices() {
			axios.post("custom-specialists/services-list")
				.then((response) => {
					this.serviceList = response.data.data.map((item) => {
						return { value:item.name, title: item.name, id: item.id };
					});
				});
		},
		async downloadPdf(pdfBytes) {
			const blob = new Blob([pdfBytes], { type: 'application/pdf' });
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = 'modified_pdf.pdf';
			link.click();
		},
		// async uploadDocument(action = 'save') {
		// 	this.v$.$validate();
		// 	if (!this.v$.$error) {
		// 		if (action == 'save_fax') {
		// 			this.savingFaxLoader = true
		// 		} else {
		// 			this.savingLoader = true
		// 		}

		// 		const formData = new FormData();
		// 		const pdfViewerInstance = this.$refs.pdfviewer.ej2Instances;
		// 		if (pdfViewerInstance) {
		// 			const annotatedPdfBlob = pdfViewerInstance.saveAsBlob();
		// 			if (annotatedPdfBlob instanceof Promise) {
		// 				annotatedPdfBlob.then((result) => {
		// 					if (result instanceof Blob) {
		// 						formData.append('pdfData', result, 'edited_file.pdf');
		// 					}
		// 					formData.append('id', this.$route.params.template_id);
		// 					Object.keys(this.form).forEach(key => {
		// 						formData.append(key, this.form[key]);
		// 					});
		// 					if (action == 'save_fax') {
		// 						formData.append('action', 'fax');
		// 					}

		// 					try {
		// 						const config = {
		// 							headers: {
		// 								'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
		// 								'Content-Type': 'multipart/form-data'
		// 							},
		// 						};

		// 						let url = ''
		// 						if (this.form.module.toLowerCase() == 'consultation') {
		// 							url = 'patient/consultations/create-by-template';
		// 						} else if (this.form.module.toLowerCase() == 'imaging') {
		// 							url = 'patient/imaging/create-by-template';
		// 						} else if (this.form.module.toLowerCase() == 'lab') {
		// 							url = 'patient/lab/create-by-template';
		// 						}

		// 						if (url.length == 0) {
		// 							this.$router.push({ name: 'patientTemplateList', params: { patient_id: this.$route.params.patient_id, module: this.form.module.toLowerCase() } });
		// 							return false;
		// 						}

		// 						axios.post(url, formData, config).then((response) => {
		// 							if (response.status == 200) {
		// 								if (action == 'save_fax') {
		// 									this.savingFaxLoader = false
		// 								} else {
		// 									this.savingLoader = false
		// 								}

		// 								this.$filters.moshaToast(response.data.message, "success");
		// 								this.$router.push({ name: 'patientTemplateList', params: { patient_id: this.$route.params.patient_id, module: this.form.module.toLowerCase() } });
		// 							}
		// 						});
		// 					}
		// 					catch (error) {
		// 						console.error('Error saving PDF with notes on the server:', error);
		// 					}
		// 				});
		// 			}
		// 		}
		// 	}
		// },
		async uploadDocument(action = 'save') {
			/* For the testing purpose open below code  */
			// console.log('action:- ', action);
			// this.onSaveExistingPdfBytes = await fetch(this.documentPath).then(res => res.arrayBuffer());
			// this.savePdfDoc = await PDFDocument.load(this.onSaveExistingPdfBytes);
			// await this.drawAnnotations('save');
			// const annotatedPdfBytes = await this.savePdfDoc.save();
			// await this.downloadPdf(annotatedPdfBytes);
			this.v$.$validate();
			if (!this.v$.$error) {
				if (action == 'save_fax') {
					this.savingFaxLoader = true;
				} else {
					this.savingLoader = true;
				}
				
				const formData = new FormData();
				this.onSaveExistingPdfBytes = await fetch(this.documentPath).then(res => res.arrayBuffer());
				this.savePdfDoc = await PDFDocument.load(this.onSaveExistingPdfBytes);
				await this.drawAnnotations('save');
				const annotatedPdfBytes = await this.savePdfDoc.save();
				// await this.downloadPdf(annotatedPdfBytes);
				const annotatedPdfBlob = new Blob([annotatedPdfBytes], { type: 'application/pdf' });

				// Append the PDF Blob to formData
				formData.append('pdfData', annotatedPdfBlob, 'edited_file.pdf');
				formData.append('id', this.$route.params.template_id);

				// Append additional form data
				Object.keys(this.form).forEach(key => {
					formData.append(key, this.form[key]);
				});

				const module = this.form.module.toLowerCase();

				if (action == 'save_fax') {
					if(module == 'consultation') {
						formData.append('action', 'fax');
					}
				}

				try {
					const config = {
						headers: {
							'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
							'Content-Type': 'multipart/form-data',
						},
					};

					// Construct the appropriate URL based on the module
					let url = '';
					let redirectUrlName = 'patientTemplateList'
					if (module === 'consultation') {
						redirectUrlName = 'PatientEChartConsultList'
						url = 'patient/consultations/create-by-template';
					} else if (module === 'imaging') {
						redirectUrlName = 'patientImagingList'
						url = 'patient/imaging/create-by-template';
					} else if (module === 'lab') {
						redirectUrlName = 'patientLabsList'
						url = 'patient/lab/create-by-template';
					}

					// If no URL was set, redirect and stop
					if (!url) {

						this.$router.push({
							name: redirectUrlName,
							params: {
								patient_id: this.$route.params.patient_id,
								module: module,
							},
						});
						return false;
					}

					// Make the axios POST request
					const response = await axios.post(url, formData, config);
					if (response.status === 200) {
						this.$filters.moshaToast(response.data.message, "success");
						if (action == 'save_fax') {
							this.savingFaxLoader = false;
						} else {
							this.savingLoader = false;
						}

						if (module === 'imaging' || module === 'lab') {
							// open in new tab
							const responseFile = await axios.post("/get-asset-link", { filename: response.data.data.filePath });
							window.open(responseFile.data, '_blank');
						}
						
						this.$router.push({
							name: redirectUrlName,
							params: {
								patient_id: this.$route.params.patient_id,
								module: module,
							},
						});
					} else {
						this.savingFaxLoader = false;
						this.$filters.moshaToast(response.data.message, "error")
						console.error('500')
					}
				} catch (error) {
						this.savingFaxLoader = false;
						if (error.response.status === 422) {
							this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						} else if (error.response.status === 500) {
							this.$filters.moshaToast(error.response.data.message, "error");
						} else {
							console.error('Error saving PDF with notes on the server:', error);
						}

				}
			}
		},
		backToList() {
			this.$router.push({ name: 'patientTemplateList', params: { patient_id: this.$route.params.patient_id, module: this.form.module.toLowerCase() } });
		},
		getProviderList() {
			axios.post("json_list/provider")
				.then((response) => {
					let tempData = [];
					$.each(response.data.data, function (key, item) {
						if (item.role_name === "Doctor" || item.role_name === "Nurse") {
							tempData.push({ value: item.user_id, title: item.full_name });
						}
					});
					this.referringProviderList = tempData;
				});
		},
		selectPatient(id, patient_name) {
			this.$store.state.patient_document.form.patient_id = id
			this.patient_search = patient_name
			this.form.patient_id = id
		},
		fetchPatientDetail() {
			axios.post("patient/detail", { 'id': this.$route.params.patient_id })
				.then((response) => {
					if (response.data.status === 200) {
						this.patient_detail = response.data.data
						this.patient_search = this.patient_detail.last_name + ", " + this.patient_detail.first_name
						// this.$store.state.loader = false;
						this.form.patient_id = this.patient_detail.id

					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},
		getSpecialistList() {
			const advanceFilter = {};
			advanceFilter.specialist_type = this.selectedService;
			advanceFilter.service_id = this.form.service_id
			this.form.specialist_id = ''
			axios.post("custom-specialists/list", { advanceFilter })
				.then((response) => {
					if (response.status == 200) {
						const details = response.data.data;
						const specialistTempData = [];
						$.each(details, function (key, detail) {
							detail.value = detail.id
							detail.isFrom = 'templateEdit';
							let name = detail.name;
							if (detail.is_preferred_specialist) {
								name += "<p class='float-end preferred-lable ms-2'>(Preferred)</p>"
							}
							detail.title = name
							specialistTempData.push(detail)
						})
						this.specialist_option = specialistTempData.sort((a, b) => {
							return b.is_preferred_specialist - a.is_preferred_specialist;
						});
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.loader = false;
					console.log(error)
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		}
	},
	mounted() {
		this.$store.state.loader = true;
		const pdfScript = document.createElement('script');
		pdfScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.min.js';
		pdfScript.onload = () => {
			const workerScript = document.createElement('script');
			workerScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.min.js';
			workerScript.onload = () => {
				this.edit();
			};
			document.head.appendChild(workerScript);
		};
		document.head.appendChild(pdfScript);

		// this.edit();
		this.getProviderList()
		this.fetchPatientDetail()
		document.addEventListener('click', this.handleClickOutside);
		let pageTitle = ''
		if (this.$route.params.module == 'imaging') {
			this.serviceList = this.imagingServiceList
			pageTitle = 'ADD IMAGING REQUISITION'
			if (this.$route.name === 'editModule') {
				pageTitle = 'EDIT IMAGING REQUISITION'
			}
		} else if (this.$route.params.module == 'consultation') {
			this.serviceList = this.consultationServiceList
			this.getConsultationsServices()
			pageTitle = 'ADD CONSULTATION'
			if (this.$route.name === 'editModule') {
				pageTitle = 'EDIT CONSULTATION'
			}
		} else if (this.$route.params.module == 'lab') {
			this.serviceList = this.labServiceList
			pageTitle = 'ADD LAB REQUISITION'
			if (this.$route.name === 'editModule') {
				pageTitle = 'EDIT LAB REQUISITION'
			}
		}
		this.pageTitle = pageTitle
		this.getSpecialistList()

		const authUserRole = localStorage.getItem('authUserRole');
		
		if (authUserRole === 'Doctor' || authUserRole === 'Nurse') {
			const userId = localStorage.getItem('userId');
			const authUserName = localStorage.getItem('authUserName');
			this.form.referring_practitioner_id = userId
			this.form.provider_name = authUserName
		}

	},
	watch: {
		'textBox.placeholder'(newValue) {
			if (newValue) {
				const textBox = this.allTextBoxes.find(tb => tb.id === this.selectedElementId);
				textBox.placeholder = newValue;

				const renderTextBox = this.renderAllTextBoxes.find(tb => tb.id === this.selectedElementId);
				renderTextBox.placeholder = newValue;

				this.wrapText(newValue, this.selectedTextBox.width, this.selectedTextBox.fontSize)
					.then(result => {
						const textBox = this.allTextBoxes.find(tb => tb.id === this.selectedElementId);
						if (textBox) {
							textBox.wrap_text_content = result.wrappedText;
							textBox.fontSize = result.fontSize;
						}

						const renderTextBox = this.renderAllTextBoxes.find(tb => tb.id === this.selectedElementId);
						if (renderTextBox) {
							renderTextBox.wrap_text_content = result.wrappedText;
							renderTextBox.fontSize = result.fontSize;
						}
					})
					.catch(error => {
						console.error('Error in wrapText:', error);
					})
			}
		},
		// allTextBoxes: {
		// 	handler(newVal) {
		// 		// Check if the selected text box has been updated
		// 		if (this.selectedTextBox && this.selectedTextBoxId) {
		// 			const textBox = this.allTextBoxes.find(tb => tb.id === this.selectedTextBoxId);
		// 			if (textBox) {
		// 				textBox.placeholder = newVal;
		// 				// Update placeholder value of the selected text box
		// 				console.log('textBox placeholder:', textBox.placeholder);
		// 				// Optionally, call your wrapText method here with updated text
		// 				// this.wrapText(updatedTextBox.placeholder, updatedTextBox.width, updatedTextBox.height, updatedTextBox.fontSize)
		// 				//     .then(result => {
		// 				//         updatedTextBox.wrap_text_content = result.wrappedText;
		// 				//         updatedTextBox.fontSize = result.fontSize;
		// 				//     })
		// 				//     .catch(error => console.error('Error in wrapText:', error));
		// 			}
		// 		}
		// 	},
		// 	deep: true,  // Ensure deep watching of the object
		// },
	}
}
</script>
<style>

@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';


#pdfViewer_fileUploadElement {
	display: none !important;
}

.e-checkbox-wrapper {
	display: none !important;
}

.pdf_search_section {
	position: absolute;
	top: 31px;
	width: 100%;
	background: #f5f5f5;
	padding: 10px;
	box-shadow: 0px 0px 6px 1px #e1e1e1;
	border-radius: 2px;
	border-top: none;
}

.pdf_search {
	padding-left: 0;
	margin-bottom: -10px;
	margin-top: -7px;
}

.pdf_search_option {
	list-style-type: none;
	line-height: normal;
	border-bottom: 1px solid #e7e4e4;
	padding: 10px;
	cursor: pointer;
}

.pdf_search_option:hover {
	background: #e1e1e1;
}

.checkbox-box input[type="checkbox"] {
	width: 25px;
	height: 25px;
	cursor: pointer;
}

/*------dropDown-------*/

.name-selcet-w-pdf .select-options {padding: 0;margin: 0;background: #fff;box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);border-radius: 0 0 5px 5px;position: absolute;width: 100%;overflow: auto;max-height: calc(var(--scale-ratio) * 180px);}

.name-selcet-w-pdf .custom-select .select-display {
	color: #0E2641;
	padding: 7px 12px;
	border-radius: 5px;
	line-height: normal;
	cursor: pointer;
	border: 1px solid #0e2641;
	background: #fff;
}

/*.name-selcet-w-pdf .custom-select .select-display .selected-list-actv{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 50px;display: inline-block;vertical-align: middle;}*/
.name-selcet-w-pdf .custom-select .select-display img {margin-left: calc(var(--scale-ratio)* 6px);width: calc(var(--scale-ratio)* 25px);}

.name-selcet-w-pdf .select-options li {
	display: block;
	border-bottom: 0.5px solid #ECE3D1;
	color: #0E2641;
	font-size: 16px;
	padding: calc(var(--scale-ratio) * 7px) calc(var(--scale-ratio) * 12px);
	line-height: normal;
	cursor: pointer;
	text-align: center;
}
.name-selcet-w-pdf .select-options li:hover{background: #F6F1E8;}
</style>