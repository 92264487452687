<template>
    <div class="row patient_chart">
        <div class="page-loader" v-if="isLoader"><img src="images/loader.svg"><span class="load_txt">Loading ...</span></div>
        <sidebar></sidebar>
        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 appointment_history allergies">
            <div class="a_header">
                <div>
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" class="form-control" placeholder="Search Prescription" ref="globalSearch" v-model="form.keyword" @keydown.enter="this.getPrescriptionList()" @input="handleInput">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length }}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList" @refresh-data="getPrescriptionList"></CustomFilter>
                        <button class="comman_brdr_btn consexport-btn" :disabled="this.selectedItems && this.selectedItems.length == 0" @click="exportconsults"> Export </button>
                    </span>
                </div>
                <button :class="{ 'add-prescription-disabled': isUserDocORNurse() == false }" class="comman_btn" @click="addPrescription">Add Prescription</button>
            </div>
            <div class="table-responsive consult-list prescriptions-table">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="hover_unset w-50px"><input class="form-check-input" type="checkbox" v-model="selectAllChk" @change="checkSelectAll"></th>
                            <!-- <th scope="col" :class="{ 'active': this.sortBy == 'written_date' }" @click="changeSort('written_date')">Entered Date <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th> -->
                            <th scope="col" :class="{ 'active': this.sortBy == 'start_date' }" @click="changeSort('start_date')"> Start Date <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'generic_name' }" @click="changeSort('generic_name')"> Medication <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'is_past_med' }" @click="changeSort('is_past_med')"> Past Med <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'is_long_term' }" @click="changeSort('is_long_term')"> Long Term <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder == 'asc' }"></th>
                            <th scope="col" class="hover_unset cursor-default"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.prescriptionList" :key="index">
                            <td><input class="form-check-input" type="checkbox" :checked="isChecked(item.id)" @change="checkSelect(item.id)"></td>
                            <!-- <td>{{ item.written_date }}</td> -->
                            <td>{{ item.start_date }}</td>
                            <td><div>{{ item.generic_name }}</div><div> {{ item.drug_name }} {{ item.strength }}</div></td>
                            <td>{{ item.is_past_med == 1 ? 'Yes' : 'No'  }}</td>
                            <td>{{ item.is_long_term == 1 ? 'Yes' : 'No'  }}</td>
                            <td class="edit_delet_link">
                                <span class="popup_link" @click="viewPrescription(item.id)">View</span>
                                <span class="popup_link" @click="deletePrescription(item.id)">Delete</span>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="modal fade fax-exportconsultation-modal" id="add-exportconsultation-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
                        <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="box">
                        <ul class="export_tabs">
                            <li :class="{ active: isFaxFormVisible }" @click="changeExportOpt('fax')">Fax</li>
                            <li :class="{ active: isEmailFormVisible }" @click="changeExportOpt('email')">Email</li>
                            <li :class="{ active: isMailFormVisible }" @click="changeExportOpt('mail')">Mail</li>
                        </ul>
                        <section class="con_detl_sec">
                            <div class="fax_menu" v-if="isFaxFormVisible">
                                <div class="row align-items-center">
                                    <label for="text" class="col-sm-4 col-form-label right-bor py-0 fs-18">Specialist(s)</label>
                                    <div class="col-sm-8 position-relative selact_dropdown cunlt_specialist_option">
                                        <selectCheckBox :dataSource="specialist_option" :labelName="tagSpecialistLabel" @clicked="selectedSpecialist" :displayDiv="false" :isUnselectAll="true" unselectLabel="None" :isShowDoctorIcon="false"></selectCheckBox>
                                    </div>
                                 </div>   

                                <div class="or_line"><span>OR</span></div>

                                <!-- disable-fax -->
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="faxForm.fax_number" @input="formatFaxNumber" @keydown="handleKeyDown">
                                        <span class="multi-rc-fax-nmber white-space-nowrap d-inline-block me-2 mt-2" v-for="(phone, index) in this.faxForm.phoneNumbers" :key="index" style="display: flex; align-items: center;">
                                            {{ phone }}
                                            <img src="/images/close-icon.svg" @click="removeSelectedFax(phone)" class="cursor-pointer ml-10" style="margin-left: calc(var(--scale-ratio) * 10px); height: calc(var(--scale-ratio) * 10px);">
                                        </span>
                                        <label class="form-label" for="typeunique_idX-2">Recipient Fax Number</label>
                                        <ValidationErrorMessageList :errors="v$.faxForm.phoneNumbers.$errors" />
                                    </div>
                                    <!-- <p class="separate-multiple-fax text-center mb-0">(use comma to separate multiple fax recipients. ex: 647777777, 647123123)</p> -->

                                </div>
                            </div>
                            <div class="email_menu" v-if="isEmailFormVisible">
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="emailForm.email_id">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Email</label>
                                        <ValidationErrorMessageList :errors="v$.emailForm.email_id.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="emailForm.email_header">
                                        <label class="form-label" for="typeunique_idX-2">Email Header</label>
                                        <ValidationErrorMessageList :errors="v$.emailForm.email_header.$errors" />
                                    </div>
                                </div>

                            </div>
                            <div class="mail_menu" v-if="isMailFormVisible">
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.name">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Name</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.name.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.address">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Address</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.address.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.city">
                                        <label class="form-label" for="typeunique_idX-2">Recipient City</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.city.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.state">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Province</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.state.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.zip">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Postal Code</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.zip.$errors" />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div class="btn_part text-center">
                            <button class="comman_brdr_btn big_btn mx36" @click="closeExportModel"> Cancel </button>
                            <button class="comman_btn big_btn mx36" @click="exportSubmit">Confirm
                                <img src="images/loader.gif" v-if="faxLoader"  style="width: calc(var(--scale-ratio) * 18px);"/>
                            </button>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import sidebar from '../sidebar.vue';
import axios from "@/scripts/axios.js";
import CustomFilter from '../../../base/CustomFilter.vue';
import useVuelidate from "@vuelidate/core";
import ValidationErrorMessageList from '../../../base/ValidationErrorMessageList.vue';
import selectCheckBox from "../../../base/selectCheckBox.vue";

export default {
        setup() {
            return { v$: useVuelidate() };
        },
        data() {
            return {
                prescriptionList:[],
                sortBy:'',
                sortOrder:'',
                filterList:[],
                isShowCustomFilter:false,
                filterOptions:[
                    {value:'received_timerange', title: "Start Date"},
                    { value:"drug_name", title: "Medication" },
                    {value:'is_past_med', title: "Past Med"},
                    {value:'is_long_term', title: "Long Term"},
                ],
                permissionList:[],
                isLoader: false,
                searchTimeout: null,
                selectAllChk: false,
                selectChk: [],
                selectedItems: [],
                allItems: [],
                isFaxFormVisible:true,
                isEmailFormVisible:false,
                isMailFormVisible:false,
                specialist_option:[],
                tagSpecialistLabel: "",
            }
        },
        components: {
            sidebar,
            CustomFilter,
            ValidationErrorMessageList,
            selectCheckBox
        },
        mounted() {
            this.resetFormData()
            this.selectedItems=[]
            document.addEventListener('click', this.handleClickOutside);
            this.getPermissionList()
            this.getPrescriptionList()
            this.getSpecialistList()
        },
        methods:{
            handleInput() {
                // Clear the previous timer if it exists
                clearTimeout(this.searchTimeout);

                // Start a new timer that triggers after 2 seconds of idle time
                this.searchTimeout = setTimeout(() => {
                    this.getPrescriptionList();
                }, 1000); // 1-second idle time
            },
            viewPrescription(id){         
                this.isLoader = true;       
                this.$router.push({ name: 'PatientEChartPrescriptionView', params: {patient_id: this.$route.params.patient_id, id:id } });
            },
            addPrescription(){
                if (this.checkPermission() && this.isUserDocORNurse()) {
                    this.$router.push(`/patient/e-chart/${this.$route.params.patient_id}/medication/prescription-add`);
                } else {
                    this.$swal({
                        title: 'Error!',
                        text: 'You Do Not Have Permission to Access',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        customClass: {
                            confirmButton: 'comman_btn permission_remove_btn'
                        },
                    });
                }
            },
            handleCustomField(values){
                this.filterList = values
                let advanceFilter={}
                values.forEach(item => {
                    let value = item.value
                    if (item.value == 'Not Set') {
                        value = ''
                    }
                    advanceFilter[item.field] = value
                });
                this.form.advanceFilter = advanceFilter
            },
            getPrescriptionList(){
                // Clear the previous timer if it exists
                clearTimeout(this.searchTimeout);

                this.$store.state.loader = true;
                axios.post('patient/prescriptions/list', {
                    patient_id: this.$route.params.patient_id,
                    keyword: this.form.keyword,
                    advanceFilter:this.form.advanceFilter,
                    sort_by: this.sortBy,
                    sort_order:this.sortOrder

                })
                .then((response) => {
                    this.$store.state.loader = false;
                    if (response.status == 200) {
                        this.prescriptionList = response.data.data
                        this.prescriptionList.forEach(item => {
                            this.allItems.push(item.id)
                        });
                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
                    this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
            },
            changeSort(fieldName){
                if (fieldName != this.sortBy) {
                    this.sortOrder = 'desc'
                } else if (this.sortOrder == 'desc') {
                    this.sortOrder = 'asc'
                } else{
                    this.sortOrder = 'desc'
                }

                this.sortBy = fieldName

                this.getPrescriptionList()
            },
            addFilter(){
                this.isShowCustomFilter = !this.isShowCustomFilter;
            },
            deletePrescription(id){
                if (!this.checkPermission() || !this.isUserDocORNurse()) {
                    this.$swal({
                        title: 'Error!',
                        text: 'You Do Not Have Permission to Access',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        customClass: {
                            confirmButton: 'comman_btn permission_remove_btn'
                        },
                    });
                    return false
                }
                this.$swal({
					title: 'Are you sure to DELETE this records?',
					text: '',
					icon: '',
					showCancelButton: true,
					confirmButtonColor: '#DD6B55',
					confirmButtonText: 'Delete',
					cancelButtonText: 'Cancel',
					closeOnConfirm: false,
					closeOnCancel: false,
                    reverseButtons: true, // This will swap the Confirm and Cancel buttons
                    customClass: {
                        container: 'my-swal-container',
                        popup: 'my-swal-popup delete-popup',
                        header: 'my-swal-header',
                        title: 'my-swal-title',
                        closeButton: 'my-swal-close-button',
                        icon: 'my-swal-icon',
                        image: 'my-swal-image',
                        content: 'my-swal-content',
                        input: 'my-swal-input',
                        actions: 'my-swal-actions',
                        confirmButton: 'my-swal-confirm-button',
                        cancelButton: 'my-swal-cancel-button',
                        footer: 'my-swal-footer'
                    }
				}).then((result) => {
					if (result.isConfirmed) {
						let url = "patient/prescriptions/delete";
						axios.post(url, {'id':id})
						.then((response) => {
							this.loader = false;
							if (response.status == 200) {
                                this.$filters.moshaToast(response.data.message, "success");
								this.getPrescriptionList()
							} else {
								this.$filters.moshaToast(response.data.message, "error");
							}
						}).catch(error => {
                            if (error.response.status === 422) {
                                this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                            } else {
                                this.$filters.moshaToast(error.response.data.message, "error");
                            }
                        }) 
						// this.$swal('Deleted!', 'Your record has been deleted.', 'success');
					} else if (result.dismiss === this.$swal.DismissReason.cancel) {
					// this.$swal('Cancelled', 'Your record is safe :)', 'error');
					}
				});
            },
            getPermissionList(){
                axios.post('user/get-permission')
                .then((response) => {
                    if (response.status == 200) {
                        this.permissionList = response.data.data
                    } else {
                        this.permissionList = []
                    }
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.permissionList = []
                    } else {
                        this.permissionList = []
                    }
                });
            },
            checkPermission(){
                if (this.permissionList && this.permissionList.prescription_access) {
                    return true
                } else {
                    return false
                }
            },
            handleClickOutside(event) {
                if (this.isShowCustomFilter && !event.target.closest('.comman_btn') && !event.target.closest('.search_drop_box') && !event.target.closest('.search') && event.target.closest('.a_header')) {
                    this.getPrescriptionList()
                    this.isShowCustomFilter = false;
                }

            },
            isUserDocORNurse() {
                let userRole = localStorage.getItem('authUserRole');
                if(userRole == 'Doctor' || userRole == 'Nurse') {
                    return true;
                }
                return false;
            },
            exportconsults(){
                this.v$.faxForm.$reset();
                this.v$.emailForm.$reset();
                this.v$.mailForm.$reset();
                Object.assign(
                    this.$store.state.prescriptions.faxForm,
                    this.$store.state.prescriptions.defaultexportfrm
                );
                Object.assign(
                    this.$store.state.prescriptions.emailForm,
                    this.$store.state.prescriptions.defaultexportfrm
                );
                Object.assign(
                    this.$store.state.prescriptions.mailForm,
                    this.$store.state.prescriptions.defaultexportfrm
                );
                this.tagSpecialistLabel = ''
                this.specialist_option.map((item) => {
                    item.checked = false;
                });

                this.isFaxFormVisible=true
                this.isEmailFormVisible=false
                this.isMailFormVisible=false

                window.$("#add-exportconsultation-model").modal("show");
            },
            changeExportOpt(type){
                this.isFaxFormVisible=false
                this.isEmailFormVisible=false
                this.isMailFormVisible=false

                if (type == 'fax') {
                    this.isFaxFormVisible=true
                } else if (type == 'email') {
                    this.isEmailFormVisible=true
                } else if (type == 'mail') {
                    this.isMailFormVisible=true
                }
            },
            formatFaxNumber(){
                const { fax_number, phoneNumbers } = this.faxForm;
                
                // Check the length of phoneNumbers after formatting
                if (this.faxForm.phoneNumbers.length >= 5 && this.faxForm.fax_number) {
                    this.faxForm.fax_number = ''; // Clear the fax_number
                    this.$filters.moshaToast("You cannot add more than five recipient fax numbers for sending.", "error");
                    return false; // Prevent further processing
                }
                
                // Call the globally defined formatFaxNumber
                const result = this.$filters.formatFaxNumber(fax_number, phoneNumbers);
                // Update the model based on the result
                this.faxForm.fax_number = result.faxNumber;
                this.faxForm.phoneNumbers = result.phoneNumbers;
            },
            removeSelectedFax(numberToRemove){
                const updatedPhoneNumbers = this.faxForm.phoneNumbers.filter(phone => phone !== numberToRemove);
                this.faxForm.phoneNumbers = updatedPhoneNumbers
            },
            handleKeyDown(event) {
                if (event.key == 'Backspace') {
                    this.faxForm.fax_number = this.faxForm.fax_number.slice(0, -1);
                    event.preventDefault();
                }
            },
            closeExportModel(){
                window.$("#add-exportconsultation-model").modal("hide");
            },
            exportSubmit(){
                let form = null
                let formName = 'faxForm'
                if (this.isFaxFormVisible) {
                    form = { ...this.faxForm };
                    form.type = "fax"
                    formName = 'faxForm';
                    form.fax_number = this.faxForm['phoneNumbers'].join(', ');
                } else if(this.isEmailFormVisible){
                    form = this.emailForm
                    form.type = "email"
                    formName = 'emailForm';
                } else if (this.isMailFormVisible){
                    form = this.mailForm
                    form.type = "mail"
                    formName = 'mailForm';
                }
                form.ids = this.selectedItems
                form.patient_id = (this.$route.params.patient_id) ? this.$route.params.patient_id : null;
                this.v$[formName].$validate();

                if (!this.v$[formName].$error) {
                    this.faxLoader = true

                    let url = "patient/prescriptions/export"
                        axios.post(url, form)
                        .then((response) => {
                                this.faxLoader = false
                                
                                if (response.status == 200) {
                                    this.selectedItems = []
                                    this.selectAllChk = false

                                    if (formName == 'faxForm') {
                                        let allSuccess = false
                                        for (const detail of response.data.data) {
                                            if (detail.status == 'success'){
                                                allSuccess = true
                                                this.$filters.moshaToast(detail.msg, "success");
                                            } else {
                                                this.$filters.moshaToast(detail.msg, "error");
                                            }
                                        }
                                        if (allSuccess) {
                                            window.$("#add-exportconsultation-model").modal("hide");
                                        }
                                    } else {
                                        this.$filters.moshaToast(response.data.message, "success");
                                        window.$("#add-exportconsultation-model").modal("hide");
                                    }
                                } else {
                                    this.$filters.moshaToast(response.data.message, "error");
                                }
                            }).catch(error => {
                                this.faxLoader = false
                                this.$filters.moshaToast(error.response.data.message, "error");
                            });
                } else {
                    this.faxLoader = false
                    for (const [key] of Object.entries(this.v$[formName])) {
                        if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
                            const myEl = document.getElementById(key);
                            if (myEl) {
                                this.$smoothScroll({
                                    scrollTo: myEl,
                                });
                                break;
                            }
                        }
                    }
                }

            },
            checkSelectAll(){
                if (this.selectAllChk) {
                    this.selectedItems = [...this.allItems];
                } else {
                    this.selectedItems = []
                }
            },
            isChecked(id){
                return this.selectedItems.includes(id);
            },
            checkSelect(id){
                // Handle individual item selection/deselection
                const index = this.selectedItems.indexOf(id); // Find index of id in selectedItems
                if (index !== -1) {
                    this.selectedItems.splice(index, 1); // Remove id from selectedItems
                } else {
                    this.selectedItems.push(id)
                }

                // Update selectAllChk based on selectedItems length
                this.selectAllChk = this.selectedItems.length === this.allItems.length;
            },
            getSpecialistList(){
                axios.post("custom-specialists/list", {specialist_type:this.form.service_id})
                    .then((response) => {
                        if (response.status == 200) {
                            const details = response.data.data;
                            details.sort((a, b) => {
                                return (b.is_preferred_specialist == true) - (a.is_preferred_specialist == true);
                            });

                            const specialistTempData = details.map((item) => {
                                let name = item.name;
                                if (item.is_preferred_specialist) {
                                    name += "<p class='preferred-label float-end mb-0 mt-10'>(Preferred)</p>"
                                }
                                if (item.address){
                                    name += "<p class='address-line mb-0'>"+item.address+"</p>"
                                }
                                return { id: item.id, name: item.name, checked: false, displayLabel: name };
                            });
                            this.specialist_option = specialistTempData;
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                        this.loader = false;
                        console.log(error)
                        if (error.response.status === 422) {
                            this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        } else {
                            this.$filters.moshaToast(error.response.data.message, "error");
                        }
                    });
            },
            selectedSpecialist(value){
                this.specialist_option = value;
				const specialist_list = this.specialist_option.map((item) => {
					return (item.checked == true) ? item.id : null;
				});
				this.faxForm.specialist_id = specialist_list.filter(n => n);
				if (this.faxForm.specialist_id.length > 0) {
                    if (this.faxForm.specialist_id.length == 1) {
                        this.tagSpecialistLabel = `${this.faxForm.specialist_id.length} Specialist Selected`;
                    } else {
                        this.tagSpecialistLabel = `${this.faxForm.specialist_id.length} Specialist(s) Selected`;
                    }
                } else {
					this.tagSpecialistLabel = "";
				}
			},
            resetFormData(){
                Object.assign(
                    this.$store.state.prescriptions.form,
                    this.$store.state.prescriptions.defaultFormData
                );
                this.v$.$reset();
            },
        },
        computed: {
            form() {
                return this.$store.state.prescriptions.form;
            },
            faxForm(){
                return this.$store.state.prescriptions.faxForm;
            },
            emailForm(){
                return this.$store.state.prescriptions.emailForm;
            },
            mailForm(){
                return this.$store.state.prescriptions.mailForm;
            }
        },
        validations() {
            return {
                form:{},
                faxForm: this.$store.state.prescriptions.validationRules.faxForm,
                emailForm: this.$store.state.prescriptions.validationRules.emailForm,
                mailForm: this.$store.state.prescriptions.validationRules.mailForm,
            };

        },
}
</script>