<template>
	<div class="col-3 left_side_bar px-0">
		<div class="sticky-scroll-box">
			<slot v-for="(item, index) in this.echartElement" :key="index">
				<div class="drop_row" v-if="item.checked">
					<div class="Selact_drodwn">
						<EchartDropDown :title="item.name" :options="eChartNavData[item.navVariable]" :urlRouteName="item.urlRouteName" @callParentFunction="passEventToParent"></EchartDropDown>
					</div>

				</div>

			</slot>
		</div>
	</div>
</template>
<script>
import EchartDropDown from "../base/EchartDropDown.vue";

export default {
	props: [
		'eChartNavData',
		'echartElement'
	],
	data() {
		return {

		};
	},
	watch: {
		
	},
	mounted() {},
	methods: {
		passEventToParent(data) {
			// Re-emit the event up to the parent (abc.vue)
			this.$emit('callParentFunction', data);
		}
	},
	components: { 
		EchartDropDown
	}
};
</script>