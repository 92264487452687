<!-- Dropdown.vue -->
<template>
	<div class="dropdown-div">
		<div class="form-control caption" :class="{ light_bg: this.showList }" @click="toggleList">
			<img src="/images/down-arrow_r_new.svg" class="img-fluid" :class="{ rotate90: this.showList }">
			<span>{{ this.$filters.strToUpperCase(this.title) }}</span> 
		</div>
		<slot v-if="this.urlRouteName">
			<router-link target="_blank" :to="{ name: this.urlRouteName,  params: { patient_id: this.$route.params.patient_id }}">
				<img id="settingIcon" src="/images/dots-icon.svg" class="img-fluid dot_icon">
			</router-link>
		</slot>
		<slot v-else>
			<img id="settingIcon" src="/images/dots-icon.svg" class="img-fluid dot_icon mt-1" @click="emitParentFunction({'action':'Listing', 'name': this.$filters.strToUpperCase(this.title)})">
		</slot>
	</div>
	<div class="list_box" v-show="showList">
		<div v-for="(item, index) in options" :key="index" class="contain e-chart-element-list">

			<span v-if="item.urlRouteName">
				<a target="_blank" :href="item.urlRouteName" class="element-item-list">{{ item.title }}</a>
			</span>
			<span v-else @click="emitParentFunction({'action':'Detail', 'name': this.$filters.strToUpperCase(this.title), id:item.id})">
				<a class="element-item-list" href="javascript:void(0)">{{ item.title }}</a>
			</span>
			<span v-if="item.desc">{{ item.desc }}</span>
			<span class="white-space-nowrap">{{ item.date }}</span>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			options: {
				type: Array,
				required: true,
			},
			title:{
		type: String, // Adjust the type based on your data structure
		default: 'Title', // Default value when not provided
		},
		urlRouteName:{
		type: String, // Adjust the type based on your data structure
		default: '', // Default value when not provided
		}

	},
	data() {
		return {
			showList: false,
		};
	},
	methods: {
		toggleList() {
			this.showList = !this.showList;
		},
		emitParentFunction(item) {
			// Emit an event with optional data (if needed)
			this.$emit('callParentFunction', item);
		}

	},
	watch: {

	},
};
</script>