<template>
	<div class="row patient_chart">
		<Sidebar></Sidebar>
		<div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 edit_record_page master-view-record">
			<div class="row">
				<div class="col-12 chart-button-edit">
					<div class="text-end page_header_btns">
						<button class="comman_brdr_btn mx15" @click="cancel">
							Cancel
						</button>
						<button class="comman_btn mx15" @click="submit">
							{{btnname}}
							<img src="images/loader.gif" v-if="loader" style="width: calc(var(--scale-ratio) * 18px);" />
						</button>
					</div>
				</div>
			</div>
			<div class="scrlbar">
				<div class="row ">
					<div class="col-12 col-sm-6 col-md-6 col-lg-6 master-record-first-col">
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title mt-0">Demographic</h4>
							</div>
							<div class="box-body containt_detail">
								<p>
									<label>Title</label> 
									<span>
										<CustomDropDown :options="title_option" :initialValue="form.title_text"
											v-model="form.title" @item-selected="handleItemSelected" fieldName="title">
										</CustomDropDown>
									</span>
								</p>
								<p>
									<label>Last Name<small class="asterisksign">*</small></label> 
									<span>
										<input type="text" class="form-control" v-model="v$.form.last_name.$model">
									</span>
								</p>
								<ValidationErrorMessageList :errors="v$.form.last_name.$errors" />

								<p>
									<label>Middle Name</label> 
									<span>
										<input type="text" class="form-control" v-model="form.middle_name">
									</span>
								</p>
								<p>
									<label>First Name<small class="asterisksign">*</small></label> 
									<span>
										<input type="text" class="form-control" v-model="v$.form.first_name.$model">
									</span>
								</p>
								<ValidationErrorMessageList :errors="v$.form.first_name.$errors" />
								<p>
									<label>Suffix</label> 
									<span>
										<CustomDropDown :options="suffix_option"
											:initialValue="form.suffix" v-model="form.suffix"
											@item-selected="handleItemSelected" fieldName="suffix">
										</CustomDropDown>
									</span>
								</p>
								<p>
									<label>Gender<small class="asterisksign">*</small></label>
									<span>
										<CustomDropDown :options="gender_option" :initialValue="this.form.gender_text"
											v-model="form.gender" @item-selected="handleItemSelected"
											fieldName="gender"></CustomDropDown>
									</span>
								</p>
								<ValidationErrorMessageList :errors="v$.form.gender.$errors" />
								<p>
									<label>Date Of Birth <small class="asterisksign">*</small></label>
									<span class="two_inputs">
										<div class="calendar _datepic master-demo-dob demog-datepick-icon-remove">
											<ejs-datepicker class="e-field form-control" v-model="form.dob"
												:max="currentDate" @change="() => { onFieldChange('dob'); calculateAge(); }" @blur="() => { onFieldChange('dob'); calculateAge(); }" :showClearButton="false" format="yyyy-MM-dd" @keyup="this.keyupdate('dob')" id="dob" ref="dob" :strictMode="true"></ejs-datepicker>
										</div>
										<aside class="mute_text ms-3">(YYYY-MM-DD)</aside>
									</span>
								</p>
								<ValidationErrorMessageList :errors="v$.form.dob.$errors" />

								<p>
									<label>Spoken Language</label>
									<span>
										<CustomDropDown :options="spoken_lang_options"
											:initialValue="form.spoken_language_text" v-model="form.spoken_language"
											@item-selected="handleItemSelected" fieldName="spoken_language">
										</CustomDropDown>
									</span>
								</p>
								<p>
									<label>Chart ID</label> 
									<span>
										<input type="text" class="form-control" v-model="form.chart_id">
									</span>
								</p>
							</div>
						</div>
						<div class="box box-bordered">
							<div class="box-header with-border">
								<h4 class="box-title">HEALTH INSURANCE</h4>
							</div>
							<div class="box-body containt_detail">
								<div class="two_inputs">
									<p><label>Health Card Number </label><span><input type="text"
												class="form-control " v-model="form.health_insurance_no"></span>
									</p>
									<p><label>Ver.</label><span><input type="text" class="form-control"
												v-model="form.health_card_ver"></span></p>
								</div>
								<ValidationErrorMessageList :errors="v$.form.health_card_ver.$errors" />
								<p><label>Health Card Province </label>
									<span class="health-card-DropDown">
										<CustomDropDown :options="insuranceProviderDataSource"
											:initialValue="form.heath_card_province_text"
											v-model="form.health_card_province_id" @item-selected="handleItemSelected"
											fieldName="health_card_province_id"></CustomDropDown>
									</span>
								</p>
							</div>
						</div>
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title">Clinic Status</h4>
							</div>
							<div class="box-body containt_detail">
								<p><label>Patient Status</label>
									<span>
										<CustomDropDown :options="status_option"
											:initialValue="form.patient_status_text" v-model="form.status"
											@item-selected="handleItemSelected" fieldName="status"></CustomDropDown>
									</span>
								</p>
								<ValidationErrorMessageList :errors="v$.form.status.$errors" />
								<p><label>Patient Status Date</label>
									<span class="calendar">
										<div class="controls">
											<div class="syncfusion-component _datepic demog-datepick-icon-remove">
												<ejs-datepicker id="patient_status_date" class="e-field form-control"
													v-model="form.patient_status_date" :max="currentDate"
													:showClearButton="false" format="yyyy-MM-dd"
													@change="onFieldChange('patient_status_date')" 
													@blur="onFieldChange('patient_status_date')" 
													@keyup="this.keyupdate('patient_status_date')" ref="patient_status_date" :strictMode="true"></ejs-datepicker>
											</div>
										</div>
									</span>
								</p>
								<ValidationErrorMessageList :errors="v$.form.patient_status_date.$errors" />
								<p class="clinic_Roster_fild">
									<label>Roster Status</label>
									<!-- <span>
										<CustomDropDown :options="roster_status_options"
											:initialValue="form.roster_status_text" v-model="form.roster_status"
											@item-selected="handleItemSelected" fieldName="roster_status" :isDisabled="true">
										</CustomDropDown>
									</span> -->
								</p>
								<p class="clinic_Roster_fild"><label>Roster Start Date</label>
									<!-- <span class="calendar">
										<div class="controls">
											<div class="syncfusion-component _datepic demog-datepick-icon-remove">
												<ejs-datepicker id="enrolment_start_date" class="e-field form-control" v-model="form.enrolment_start_date"
												:max="currentDate" :showClearButton="false" format="yyyy-MM-dd"
												@change="onFieldChange('enrolment_start_date')" 
												@blur="onFieldChange('enrolment_start_date')" 
												@keyup="this.keyupdate('enrolment_start_date')" ref="enrolment_start_date" :strictMode="true" :readonly="true"></ejs-datepicker>
											</div>
										</div>
									</span> -->
								</p>
								<p class="clinic_Roster_fild"><label>Roster Termination Date</label>
									<!-- <span class="calendar">
										<div class="controls">
											<div class="syncfusion-component _datepic demog-datepick-icon-remove">
												<ejs-datepicker id="enrolment_termination_date" class="e-field form-control" v-model="form.enrolment_termination_date"
												:max="currentDate" :showClearButton="false" format="yyyy-MM-dd"
												@change="onFieldChange('enrolment_termination_date')" 
												@blur="onFieldChange('enrolment_termination_date')" 
												:readonly="isRosterStatusNotNr"
												@keyup="this.keyupdate('enrolment_termination_date')" ref="enrolment_termination_date" :strictMode="true"></ejs-datepicker>
											</div>
										</div>
									</span> -->
									<span class="updatesfiles-line text-center mb-0">(Updates Monthly from RCX Files)</span>
								</p>
								<p class="clinic_Roster_fild"><label>Roster Termination Reason</label>
									<!-- <span>
										<CustomDropDown :options="termination_reason_option"
											:initialValue="form.enrolment_status_text" v-model="form.enrolment_status"
											@item-selected="handleItemSelected" fieldName="enrolment_status" :isDisabled="isRosterStatusNotNr" :key="rosterStatusReasonKey">
										</CustomDropDown>
									</span> -->
								</p>
								<p class="clinic_Roster_fild"><label>Primary Physician</label>
									<!-- <span>
										<CustomDropDown :options="mrpProviderDataSource"
											:initialValue="form.physician_text" v-model="form.provider_id"
											@item-selected="handleItemSelected" fieldName="provider_id" :isDisabled="true">
										</CustomDropDown>
									</span> -->
								</p>

							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-6 master-record-second-col">
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title mt-0">Contact Information</h4>
							</div>
							<div class="box-body containt_detail">
								<p><label>Cell Phone <small class="asterisksign">*</small></label> <span><input type="text" class="form-control "
											v-model="form.cell_phone"></span></p>
								<ValidationErrorMessageList :errors="v$.form.cell_phone.$errors" />
								<p><label>Email </label> <span><input type="text" class="form-control "
											v-model="form.email"></span></p>
								<ValidationErrorMessageList :errors="v$.form.email.$errors" />

								<p><label>Residential Address </label> <span><input type="text"
											class="form-control " v-model="form.residential_address"></span></p>
								<p><label>Residential City </label> <span><input type="text" class="form-control "
											v-model="form.residential_city"></span></p>
								<p><label>Residential Province </label> <span><input type="text" class="form-control "
									v-model="form.residential_state"></span></p>
								<p><label>Residential Country </label> <span><input type="text" class="form-control "
									v-model="form.residential_country"></span></p>
								<p><label>Residential Postal</label> <span><input type="text" class="form-control "
											v-model="form.residential_zip"></span></p>
								<p><label>Home Phone</label> <span><input type="text" class="form-control "
											v-model="form.home_phone"></span></p>
								<div class="two_inputs">
									<p><label>Work Phone</label> <span><input type="text" class="form-control "
												v-model="form.work_phone"></span></p>
									<p><label>Ext</label><span><input type="text" class="form-control"
												v-model="form.work_phone_ext"></span></p>
								</div>
								<p class="address_line mailing-address-with-checkbox">
									<label class="border-0 p-0">Mailing Address Same as Residential</label>
									<span class="ms-3 p-0 lh-1 w-auto">
										<input class="form-check-input m-0 align-middle" type="checkbox" v-if="form.is_same_residential_mailing" checked v-model="form.is_same_residential_mailing">
										<input class="form-check-input m-0 align-middle" type="checkbox" v-else checked v-model="form.is_same_residential_mailing">
									</span>
								</p>

								<slot v-if="!form.is_same_residential_mailing">
									<p><label>Mailing Address </label> <span><input type="text" class="form-control"
												v-model="form.mailing_address"></span></p>
									<p><label>Mailing City </label> <span><input type="text" class="form-control"
												v-model="form.mailing_city"></span></p>

									<p><label>Mailing Province </label> <span><input type="text" class="form-control "
									v-model="form.mailing_state"></span></p>
									<p><label>Mailing Country </label> <span><input type="text" class="form-control "
										v-model="form.mailing_country"></span></p>

									<p><label>Mailing Postal</label> <span><input type="text" class="form-control "
										v-model="form.mailing_zip"></span></p>	
								</slot>

							</div>
						</div>
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title">ANNOTATIONS</h4>
							</div>
							<div class="box-body containt_detail">
								<p class="align-items-start mb-3"><label class="mw-auto">Notes</label> <span><textarea class="form-control" v-model="form.notes"
											rows="3"></textarea></span></p>
								<p class="align-items-start mb-3"><label class="mw-auto">Alerts</label> <span><textarea class="form-control" v-model="form.alert"
											rows="3"></textarea></span></p>
							</div>
						</div>
					</div>
				</div>
				
				<div class="row">
					<div class="col-12 col-sm-6 col-md-6 col-lg-6 master-record-first-col">
						<div class="box box-bordered ">
							<div class="box-header with-border">
								<h4 class="box-title">CLINICAL CARE TEAM</h4>
							</div>
							<div class="box-body containt_detail">
								<p>
									<label>Family Physician Last Name</label> 
									<span>
										<input type="text" class="form-control" v-model="form.family_physician_last_name">
									</span>
								</p>
								<p>
									<label>Family Physician First Name </label> 
									<span><input type="text" class="form-control" v-model="form.family_physician_first_name"></span>
								</p>
								<p>
									<label>Family Physician Phone </label> 
									<span><input type="text" class="form-control" v-model="form.family_physician_phone"></span>
								</p>
								<p>
									<label>Family Physician Fax </label> 
									<span><input type="text" class="form-control" v-model="form.family_physician_fax"></span>
								</p>
								<p>
									<label>Family Physician Address </label> 
									<span><input type="text" class="form-control" v-model="form.family_physician_address"></span>
								</p>
								<p>
									<label>Family Physician City </label> 
									<span><input type="text" class="form-control" v-model="form.family_physician_city"></span>
								</p>
								<p>
									<label>Family Physician Province </label> 
									<span><input type="text" class="form-control" v-model="form.family_physician_state"></span>
								</p>
								<p>
									<label>Family Physician Country </label> 
									<span><input type="text" class="form-control" v-model="form.family_physician_country"></span>
								</p>
								<p>
									<label>Family Physician Postal </label> 
									<span><input type="text" class="form-control" v-model="form.family_physician_zip"></span>
								</p>
							</div>
						</div>
					</div>
					<div class="col-12 col-sm-6 col-md-6 col-lg-6 master-record-second-col">
						<div class="box box-bordered ">
							<div class="box-header with-border opacity-0">
								<h4 class="box-title">CLINICAL CARE TEAM</h4>
							</div>
							<div class="box-body containt_detail">
								<p>
									<label>Referred Physician Last Name </label> 
									<span><input type="text" class="form-control " v-model="form.referred_physician_last_name"></span>
								</p>
								<p>
									<label>Referred Physician First Name </label> 
									<span><input type="text" class="form-control " v-model="form.referred_physician_first_name"></span>
								</p>
								<p>
									<label>Referred Physician Phone </label> 
									<span><input type="text" class="form-control " v-model="form.referred_physician_phone"></span>
								</p>
								<p>
									<label>Referred Physician Fax </label> 
									<span><input type="text" class="form-control " v-model="form.referred_physician_fax"></span>
								</p>
								<p>
									<label>Referred Physician Address </label> 
									<span><input type="text" class="form-control " v-model="form.referred_physician_address"></span>
								</p>
								<p>
									<label>Referred Physician City </label> 
									<span><input type="text" class="form-control " v-model="form.referred_physician_city"></span>
								</p>
								<p>
									<label>Referred Physician Province </label> 
									<span><input type="text" class="form-control " v-model="form.referred_physician_state"></span>
								</p>
								<p>
									<label>Referred Physician Country </label> 
									<span><input type="text" class="form-control " v-model="form.referred_physician_country"></span>
								</p>
								<p>
									<label>Referred Physician Postal </label> 
									<span><input type="text" class="form-control " v-model="form.referred_physician_zip"></span>
								</p>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<slot v-for="(item,index) in form.alternative_contact_detail" :key="index">
						<div class="col-12 col-sm-6 col-md-6 col-lg-6"  :class="{ 'master-record-first-col': index==0, 'master-record-second-col': index==1}">
							<div class="box box-bordered ">
								<div class="box-header with-border">
									<h4 class="box-title" :class="{ 'opacity-0': index > 0 }">ALTERNATIVE CONTACT</h4>
								</div>
								<div class="box-body containt_detail" :class="{ 'right_collumn': shouldHighlightRow(index) }">
									<p><label>Contact {{ index + 1 }} First Name</label> <span><input
												type="text" class="form-control w-300"
												v-model="item.first_name" @change="checkAlterContactValidate"></span>
									</p>
									<div class="error-msg" v-if="this.alternativeErrors[`first_name_${index}`]">
										<ul class="mb-0 p-0 fs-14">
											<li class="d-block text-danger">
												{{ this.alternativeErrors[`first_name_${index}`] }}
											</li>
										</ul>
									</div>
									<p><label>Contact {{ index + 1 }} Last Name</label> <span><input type="text"
												class="form-control w-300" v-model="item.last_name" @change="checkAlterContactValidate"></span></p>
									<div class="error-msg" v-if="this.alternativeErrors[`first_name_${index}`]">
										<ul class="mb-0 p-0 fs-14">
											<li class="d-block text-danger">
												{{ this.alternativeErrors[`last_name_${index}`] }}
											</li>
										</ul>
									</div>

									<p><label>Contact {{ index + 1 }} Purpose</label> <span>
										<CustomDropDown :options="purposeList"
											:initialValue="item.contact_purpose_text"
											@item-selected="handleItemSelected" :fieldName="getFieldName(index)">
										</CustomDropDown>
										</span>
									</p>
									<div class="error-msg" v-if="this.alternativeErrors[`contact_purpose_${index}`]">
										<ul class="mb-0 p-0 fs-14">
											<li class="d-block text-danger">
												{{ this.alternativeErrors[`contact_purpose_${index}`] }}
											</li>
										</ul>
									</div>
									<p><label>Contact {{ index + 1 }} Relationship</label> <span>
											<CustomDropDown :options="relationshipList"
												:initialValue="item.relationship_text"
												@item-selected="handleItemSelected" :fieldName="getRelationFieldName(index)">
											</CustomDropDown>
										</span>
									</p>
									<div class="error-msg" v-if="this.alternativeErrors[`relationship_${index}`]">
										<ul class="mb-0 p-0 fs-14">
											<li class="d-block text-danger">
												{{ this.alternativeErrors[`relationship_${index}`] }}
											</li>
										</ul>
									</div>
									<p><label>Contact {{ index + 1 }} Cell Phone</label> <span><input
												type="text" class="form-control "
												v-model="item.cell_phone" @change="checkAlterContactValidate"></span></p>
									<div class="error-msg" v-if="this.alternativeErrors[`cell_phone_${index}`]">
										<ul class="mb-0 p-0 fs-14">
											<li class="d-block text-danger">
												{{ this.alternativeErrors[`cell_phone_${index}`] }}
											</li>
										</ul>
									</div>


									<p><label>Contact {{ index + 1 }} Home Phone</label> <span><input
												type="text" class="form-control "
												v-model="item.residence_phone"></span></p>
									<div class="two_inputs">
										<p><label>Contact {{ index +1 }} Work Phone</label> <span><input
													type="text" class="form-control "
													v-model="item.work_phone"></span></p>
										<p><label>Ext</label><span><input type="text"
													class="form-control"
													v-model="item.work_phone_ext"></span></p>
									</div>
									<p><label>Contact {{ index + 1 }} Email</label> <span><input type="text"
												class="form-control " v-model="item.email"></span></p>
									<p><label>Contact {{ index + 1 }} Note</label> <span><textarea
												class="form-control " v-model="item.contact_note"
												rows="1"></textarea></span></p>
								</div>
							</div>
						</div>
					</slot>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import { mapState } from 'vuex';
	import Sidebar from './Sidebar.vue';
	import axios from "@/scripts/axios.js";
	import useVuelidate from "@vuelidate/core";
	import $ from "jquery";
	import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
	import CustomDropDown from '../base/formFields/CustomDropDown.vue';
	import ValidationErrorMessageList from '../base/ValidationErrorMessageList.vue';
	import moment from "moment";

	export default {
		data() {
			return {
				inputWidth: 0,
				altContactInfo: [],
				gender_option: [
					{ value: "M", title: "Male" },
					{ value: "F", title: "Female" },
					{ value: "O", title: "Other" },
					{ value: "U", title: "Unknown" },
				],
				spoken_lang_options: [
					{ value: "ENG", title: "English" },
					{ value: "FRE", title: "French" },
					{ value: "OTH", title: "Other" },
				],
				status_option: [
					{ value: "AC", title: "Active" },
					{ value: "IN", title: "Inactive" },
					{ value: "DE", title: "Deceased" },
				],
				mrpProviderDataSource: [],
				insuranceProviderDataSource: [
					{ value: "AB", title: "AB - Alberta" },
					{ value: "BC", title: "BC - British Columbia" },
					{ value: "MB", title: "MB - Manitoba" },
					{ value: "NB", title: "NB - New Brunswick" },
					{ value: "NL", title: "NL - Newfoundland" },
					{ value: "NS", title: "NS - Nova Scotia" },
					{ value: "ON", title: "ON - Ontario" },
					{ value: "PE", title: "PE - Prince Edward Island" },
					{ value: "QC", title: "QC - Quebec" },
					{ value: "SK", title: "SK - Saskatchewan" },
					{ value: "NT", title: "NT - Northwest Territories" },
					{ value: "NU", title: "NU - Nunavut" },
					{ value: "YT", title: "YT - Yukon" },
					{ value: "OT", title: "OT - Other" },
				],
				roster_status_options: [
					{ value: "RO", title: "RO-Rostered" },
					{ value: "NR", title: "NR-Not Rostered" }
				],
				termination_reason_option: [],
				purposeList: [{
					'value': 'SDM',
					'title': 'Substitute Decision Maker',
				},
				{
					'value': 'EC',
					'title': 'Emergency Contact',
				}
				],
				relationshipList: [
					{ 'value': 'Father', 'title': 'Father' },
					{ 'value': 'Mother', 'title': 'Mother' },
					{ 'value': 'Parent', 'title': 'Parent' },
					{ 'value': 'Husband', 'title': 'Husband' },
					{ 'value': 'Wife', 'title': 'Wife' },
					{ 'value': 'Partner', 'title': 'Partner' },
					{ 'value': 'Son', 'title': 'Son' },
					{ 'value': 'Daughter', 'title': 'Daughter' },
					{ 'value': 'Brother', 'title': 'Brother' },
					{ 'value': 'Sister', 'title': 'Sister' },
					{ 'value': 'Uncle', 'title': 'Uncle' },
					{ 'value': 'Aunt', 'title': 'Aunt' },
					{ 'value': 'GrandFather', 'title': 'GrandFather' },
					{ 'value': 'GrandMother', 'title': 'GrandMother' },
					{ 'value': 'Guardian', 'title': 'Guardian' },
					{ 'value': 'Foster Parent', 'title': 'Foster Parent' },
					{ 'value': 'Next of Kin', 'title': 'Next of Kin' },
					{ 'value': 'Administrative Staff', 'title': 'Administrative Staff' },
					{ 'value': 'Care Giver ', 'title': 'Care Giver' },
					{ 'value': 'Power Of Attorney ', 'title': 'Power Of Attorney' },
					{ 'value': 'Insurance', 'title': 'Insurance' },
					{ 'value': 'Guarantor', 'title': 'Guarantor' },
					{ 'value': 'Other', 'title': 'Other' },
				],
				title_option: [
					{ value: "Mr.", title: "Mr." },
					{ value: "Mrs.", title: "Mrs." },
					{ value: "Ms.", title: "Ms." },
					{ value: "Miss", title: "Miss" },
					{ value: "Mx", title: "Mx" }
				],
				currentDate: new Date(),
				showGenderList: false,
				suffix_option: [
					{ value: "Jr", title: "Jr" },
					{ value: "Sr", title: "Sr" },
					{ value: "II", title: "II" },
					{ value: "III", title: "III" },
					{ value: "IV", title: "IV" },
				],
				showTitleList: false,
				alternativeErrors:[],
				isRosterStatusNotNr: false,
				rosterStatusReasonKey: 0,
			}
		},
		setup: () => {
			return { v$: useVuelidate() };
		},
		components: {
			Sidebar,
			'ejs-datepicker': DatePickerComponent,
			CustomDropDown,
			ValidationErrorMessageList
		},
		async created() {
			this.$store.state.loader = true;
			this.fetchProviderData();
			this.fetchTerminationReasonData();
			if (this.$route.name === "PatientEdit") {
				this.getOtherContact()
				this.edit();
			} else {
				this.$store.state.loader = false;
			}
		},
		computed: {
			...mapState(['patientId']),
			form() {
				return this.$store.state.patient.form;
			},
			vuelidateExternalResults() {
				return this.$store.state.patient.vuelidateExternalResults;
			},
			btnname() {
				return this.$route.name === "PatientEdit" ? "Update" : "Create";
			},
			title() {
				return this.$route.name === "PatientEdit" ? "Edit" : "Create";
			},
			displaySidebar() {
				return this.$route.name === "PatientEdit" ? true : false;
			}
		},
		validations() {
			return this.$store.state.patient.validationRules;
		},
		mounted() {
			if (this.$route.name !== "PatientEdit") {
				this.resetforms();
			}
			document.addEventListener('click', this.handleClickOutside);

			var $this = this;
			$(document).on("click", '._datepic', function () {
				const inputField = $(this).find('input');
				const inputId = inputField.attr('id');
				$this.openDatePicker(inputId);
			});
			this.calculateAge();
		},
		watch: {
			"form.health_card_ver"(newVal, oldVal) {
				if (newVal && newVal !== oldVal) {
					this.form.health_card_ver = newVal.toUpperCase();
				}
			}
		},
		methods: {
			openDatePicker(refName) {
				var schObj = document.querySelector(`#${refName}`).ej2_instances[0];
				schObj.show();
			},
			keyupdate(obj){
				if($(`#${obj}-error`)){
					$(`#${obj}-error`).text("");
				}
				const inputValue = $(`#${obj}`).val();
				var sanitizedInput = inputValue.replace(/[^\d]/g, '');
				if (sanitizedInput.length > 8) {
					sanitizedInput = sanitizedInput.substring(0, 8);
				}
				var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function(_, y, m, d) {
					let result = y;
					if (m) result += '-' + m;
					if (d) result += '-' + d;
					return result;
				});
				$(`#${obj}`).val(formattedDate);
			},
			async edit() {
				if (this.$route.name === "PatientEdit") {
					axios.post("patient/retrieve", { 'id': this.$route.params.patient_id })
						.then((response) => {
							if (response.data.status === 200) {
								let $this = this
								let detail = response.data.data;
								detail.gender_text = (this.gender_option.find(item => item.value === detail.gender) || {}).title || "Select Gender";
								detail.title_text = (this.title_option.find(item => item.value === detail.title) || {}).title || "Select Title";
								detail.heath_card_province_text = (this.insuranceProviderDataSource.find(item => item.value === detail.health_card_province_id) || {}).title || "Select Title";
								detail.roster_status_text = (this.roster_status_options.find(item => item.value === detail.roster_status) || {}).title || "Select Roster Status";
								if(detail.roster_status_text === 'NR-Not Rostered')
								{
									this.isRosterStatusNotNr = true;	
								}

								detail.patient_status_text = (this.status_option.find(item => item.value === detail.status) || {}).title || "Select Patient Status Date";
								detail.physician_text = (this.mrpProviderDataSource.find(item => item.value === detail.provider_id) || {}).title || "Select Primary Physician";
								detail.spoken_language_text = (this.spoken_lang_options.find(item => item.value === detail.spoken_language) || {}).title || "Select Spoken Language";
								detail.enrolment_status_text = (this.termination_reason_option.find(item => item.title === detail.termination_reason) || {}).title || "Select Roster Termination Reason";
								console.log('detail.enrolment_status_text:- ', detail.enrolment_status_text);

								this.calculateAge();

								if (detail.alternative_contact_detail.length == 0) {
									detail.alternative_contact_detail = this.$store.state.patient.defaultFormData.alternative_contact_detail
								} else if (detail.alternative_contact_detail.length == 1){
									detail.alternative_contact_detail[1] = { "first_name":"", "contact_purpose_text":"Select Purpose", "relationship_text":"Select Relationship"}
								}

								if (detail.alternative_contact_detail.length > 0) {
									$.each(detail.alternative_contact_detail, function (key, detail) {
										detail.contact_purpose_text =  ($this.purposeList.find(item => item.value == detail.contact_purpose) || {}).title || "Select Purpose";
										detail.relationship_text    =  ($this.relationshipList.find(item => item.value == detail.relationship) || {}).title || "Select Relationship";
									});
								}

								this.$store.state.patient.form = detail;
								console.log('this.$store.state.patient.form:- ', this.$store.state.patient.form);

								this.$store.state.loader = false;

							} else {
								this.$filters.moshaToast(response.data.message, "error")
								this.$store.state.loader = false;
							}
						})
						.catch(error => {
							this.$filters.moshaToast(error.message, "error")
							this.$store.state.loader = false;
						});
				}
			},
			calculateAge() {
				let dob = this.form.dob
				if (dob) {
					const birthDate = new Date(dob);
					const currentDate = new Date();

					let years = currentDate.getFullYear() - birthDate.getFullYear();
					this.form.age = `${years}`;
					return true
				}
				this.form.age = 0;
			},
			getOtherContact() {
				axios.post(`/patient/alternative-contact/list`, { 'patient_id': this.$route.params.patient_id })
					.then((response) => {
						if (response.data.status === 200) {
							this.altContactInfo = response.data.data
						} else {
							this.$filters.moshaToast(response.data.message, "error");
						}
					}).catch(error => {
						this.$filters.moshaToast(error.message, "error");
					});
			},
			createContact() {
				const url = this.$router.resolve({
					path: `/patient/alter-native-contact/${this.$route.params.patient_id}/create`,
					// query: { target: '_blank' }
				}).href;

				window.open(url, '_blank');
				// this.$router.push(`/patient/alter-native-contact/${this.$route.params.patient_id}/create`);   
			},
			editContact(id) {
				// this.$router.push(`/patient/alter-native-contact/${this.$route.params.patient_id}/retrieve/${id}`);   
				const url = this.$router.resolve({
					path: `/patient/alter-native-contact/${this.$route.params.patient_id}/retrieve/${id}`,
					// query: { target: '_blank' }
				}).href;

				window.open(url, '_blank');
			},
			contactList() {
				const url = this.$router.resolve({
					path: `/patient/alter-native-contact/${this.$route.params.patient_id}`,
					// query: { target: '_blank' }
				}).href;

				window.open(url, '_blank');
				// this.$router.push(`/patient/alter-native-contact/${this.$route.params.patient_id}`);   
			},
			enrollmentHistory() {
				const url = this.$router.resolve({
					path: `/patient/enrollment-history/${this.$route.params.patient_id}`,
					// query: { target: '_blank' }
				}).href;

				window.open(url, '_blank');
				// this.$router.push(`/patient/enrollment-history/${this.$route.params.patient_id}`);   
			},
			dateFormat(date) {
				if (date == '' || date == null) {
					return ''
				}
				const dateObject = new Date(date);
				const options = { year: 'numeric', month: 'long', day: 'numeric' };

				// Adjust the options based on your desired date format
				return dateObject.toLocaleDateString('en-US', options);
			},
			fetchProviderData() {
				var $this = this
				axios.post("json_list/provider", { 'roleId': '' })
					.then((response) => {
						const providerTempData = [];
						const details = response.data.data;
						$.each(details, function (key, detail) {
							if (!($this.$route.name == "PatientCreate" && detail.deleted_datetime)) {
								providerTempData.push({
									value: detail.user_id,
									title: detail.full_name,
								})
							}
						})
						this.mrpProviderDataSource = providerTempData
					})
			},
			getAltContactClass(index) {
				return index % 2 === 0 ? 'me-1' : '';
			},
			fetchTerminationReasonData() {
				axios.get("json_autocomplete/get-termination-reason")
					.then((response) => {
						const reasonTempData = []
						const details = response.data.data;
						$.each(details, function (key, detail) {
							reasonTempData.push({
								value: detail['id'],
								title: detail['reason_name']
							})
						})
						this.termination_reason_option = reasonTempData;
					})
			},
			resetforms() {
				this.$store.state.patient.form = {}
				Object.assign(
					this.$store.state.patient.form,
					this.$store.state.patient.defaultFormData
				);
			},
			submit() {
				this.v$.$validate();
				const $this = this
				if (!this.v$.$error && this.checkAlterContactValidate()) {
					this.loader = true
					const form = this.form;
					let url
					if (this.$route.name === "PatientEdit") {
						url = "patient/update";
					} else {
						url = "patient/store";
					}

					axios.post(url, form)
						.then((response) => {
							if (response.status == 200) {
								this.loader = false
								setTimeout(function () {
									$this.$filters.moshaToast(response.data.message, "success")
									$this.$router.push(
										`/patient/chart/` + response.data.data.id
									);
								}, 400);
							} else {
								this.loader = false
								this.$filters.moshaToast(response.data.message, "error")
							}
						}).catch(error => {
							this.$store.state.loader = false;
							if (error.response.status === 422) {
								this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
								console.log(Object.values(error.response.data.data).join(', '))
							} else {
								this.$filters.moshaToast(error.response.data.message, "error");
							}
						})
				} else {
					for (const [key] of Object.entries(this.v$.form)) {
						if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
							const myEl = document.getElementById(key);
							if (myEl) {
								this.$smoothScroll({
									scrollTo: myEl,
								});
								break;
							}
						}
					}
				}
			},
			checkAlterContactValidate(){
				const $this = this
				let isValidAlternativeContact = true
				this.alternativeErrors = []
				$.each(this.form.alternative_contact_detail, function (key, detail) {
					if (detail.relationship || detail.contact_purpose || detail.first_name || detail.last_name || detail.cell_phone){
						if (!detail.first_name || !detail.last_name || !detail.cell_phone) {
							isValidAlternativeContact = false
							if (!detail.first_name) {
								$this.alternativeErrors[`first_name_${key}`] = `Please enter contact ${key + 1} first name`
							}
							if (!detail.last_name) {
								$this.alternativeErrors[`last_name_${key}`] = `Please enter contact ${key + 1} last name`
							}
							if (!detail.cell_phone) {
								$this.alternativeErrors[`cell_phone_${key}`] = `Please enter contact ${key + 1} cell phone`
							}
							if (!detail.contact_purpose) {
								$this.alternativeErrors[`contact_purpose_${key}`] = `Please enter contact ${key + 1} Purpose`
							}
							if (!detail.relationship) {
								$this.alternativeErrors[`relationship_${key}`] = `Please enter contact ${key + 1} Relationship`
							}
						}
					}
				});
				return isValidAlternativeContact
			},
			cancel() {
				if (this.$route.name === "PatientEdit") {
					this.$router.push(
						`/patient/chart/${this.$route.params.patient_id}`
					);
				} else {
					this.$router.push(
						`/`
					);
				}
			},
			shouldHighlightRow(index) {
				// Return true for rows with indices 1, 3, 5, 7, ...
				return (index % 2 !== 0);
			},
			updateInputWidth(event) {
				this.inputWidth = event.target.value.length * 10; // Adjust multiplier as needed
			},
			getTextWidth(text, referenceElement) {
				// Helper function to calculate the width of the text using the reference element's font properties
				const tempElement = document.createElement('span');
				tempElement.style.font = window.getComputedStyle(referenceElement).font;
				tempElement.textContent = text;
				document.body.appendChild(tempElement);

				const width = tempElement.getBoundingClientRect().width;

				// Remove the temporary element from the DOM
				document.body.removeChild(tempElement);

				return width;
			},
			handleClickOutside(event) {

				for (let i = 0; i < this.form.length; i++) {
					const dropdownElement = this.$refs[`dropdown${i}`];
					if (dropdownElement && !dropdownElement.contains(event.target)) {
						this.$set(this.forms, i, {
							...this.forms[i],
							showTitleList: false,
						});
					}
				}

			},
			isValidDate(dateString) {
				const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
				const date = new Date(year, month - 1, day);
				return date.getFullYear() === year && (month === 0 || date.getMonth() + 1 === month) && (day === 0 || date.getDate() === day);
			},
			onFieldChange(fieldName) {
				var obj = fieldName;
				const sanitizedInput = $(`#${obj}`).val();
				var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
					let result = y;
					if (m) result += '-' + m;
					if (d) result += '-' + d;
					return result;
				});

				// Get or create the error message element
				let errorElement = $(`#${obj}-error`);
				if (errorElement.length === 0) {
					errorElement = $(`<div id="${obj}-error" style="color: red;margin-top: 5px;"></div>`);
					$(`#${obj}`).parent().after(errorElement);
				}
				const isValidDate = (formattedDate!='') ? this.isValidDate(formattedDate) : false;
				if (isValidDate) {
					errorElement.text(''); // Clear error message if the date is valid
					this.form[fieldName] = moment(sanitizedInput).format('YYYY-MM-DD');
				} else {
					if(formattedDate!=''){
						errorElement.text('Invalid Date');
					}
					$(`#${obj}`).val("");
				}
			},
			handleItemSelected({ fieldName, item }) {
				console.log(fieldName)
				// Update the form field with the selected item

				if (fieldName == 'alternative_contact_detail.0.contact_purpose' || fieldName ==  'alternative_contact_detail.1.contact_purpose') {
					if (fieldName == 'alternative_contact_detail.0.contact_purpose') {
						this.form.alternative_contact_detail[0].contact_purpose = item.value;
						this.form.alternative_contact_detail[0].contact_purpose_text = item.title;
					} else {
						this.form.alternative_contact_detail[1].contact_purpose = item.value;
						this.form.alternative_contact_detail[1].contact_purpose_text = item.title;
					}
					this.checkAlterContactValidate()
				} else if (fieldName == 'alternative_contact_detail.0.relationship' || fieldName ==  'alternative_contact_detail.1.relationship') {
					if (fieldName == 'alternative_contact_detail.0.relationship') {
						this.form.alternative_contact_detail[0].relationship = item.value;
						this.form.alternative_contact_detail[0].relationship_text = item.title;
					} else {
						this.form.alternative_contact_detail[1].relationship = item.value;
						this.form.alternative_contact_detail[1].relationship_text = item.title;
					}
					this.checkAlterContactValidate()
				} else if (fieldName == 'roster_status') {
					if(item.value === 'RO')
					{
						this.isRosterStatusNotNr = false;
					} else {
						this.form.enrolment_termination_date = '';
						$("#enrolment_termination_date").val('');

						this.rosterStatusReasonKey++;
						this.form.enrolment_status = null;
						this.form.enrolment_status_text = "Select Roster Termination Reason";

						this.isRosterStatusNotNr = true;
					}
					this.form[fieldName] = item.value;
				} else if (fieldName == 'enrolment_status') {
					this.form.enrolment_status = item.value;
					this.form.enrolment_status_text = item.title;
					this.form.termination_reason = item.title;
				} else {
					this.form[fieldName] = item.value;
				}
			},
			getFieldName(index) {
				return `alternative_contact_detail.${index}.contact_purpose`;
			},
			getRelationFieldName(index) {
				return `alternative_contact_detail.${index}.relationship`;
			}

		},
		beforeCreate() {
		},
	}
</script>