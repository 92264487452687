<template>
	<div class="d-flex justify-content-between align-items-center e_c-header">
		<div class="d-flex align-items-center">
			<div class="selact_dropdown echartelement-select">
				<selectCheckBox :dataSource='echartElement' labelName="Select Your E-Chart Elements" @clicked="selected_provider" :displayDiv="displayDiv" :key="select_box_key" :isShowDoctorIcon="false" :isUnselectAll="isUnselectAll"/>
			</div>
			<span class="text_link ps-3 cursor-pointer" @click="handleButtonClick">{{ boxElementToggleBtnText }}</span>
		</div>
		<div class="search e-chart-searchUpdate">
			<img class="serch_icon" src="/images/search-icon.svg" alt="">
			<!-- <input type="text" @keyup="patientSearch" class="form-control" placeholder="Search UpdateToDate" v-model="patient_search" @blur="patientSearch" ref="globalSearch"> -->
			<input type="text" @keyup.enter="querySearch" class="form-control" placeholder="Search UpdateToDate" v-model="query_search" ref="globalSearch">
		</div>
		<button class="comman_brdr_btn" @click="echarTransfer"> TRANSFER </button>
	</div>

	<div class="modal fade" id="e-chart-transfer" tabindex="-1" style="display: none;" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="closeEChartTransferPop"></button>
                    <div class="box">
						<h2 class="popup_title">E-CHART TRANSFER</h2>
						<div class="hdng_con">
							<div class="heading">Date Range</div>
							<span class="con_tx">Please select the date range you would like the E-Chart report to cover.</span>
						</div>
						<div class="row align-items-center justify-content-center time_set_part">
							<div class="col-md-6 text-center">
								<div class="contain_bx">
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.form.e_chart_transfer.is_all_time_range" @change="handleAllTImeRangeChange" ></span>
										<label>All Time Range</label>
									</div>
								</div>
							</div>
							<div class="col-md-6 text-center left_con_box">
								<div class="position-relative mx-4" :class="{'time-range-disabled-form': this.form.e_chart_transfer.is_all_time_range}">
									<div class="contain_bx">
										<label for="text"><span class="cursor-text">From</span></label>
										<div class="calendar e-chart-clnder" @click="openDatePicker('start')">
											<ejs-datepicker class="text-center e-field form-control" ref="startDatePicker"
												:format="{ skeleton: 'full' }" :showClearButton="false"
												v-model="this.selectedStartDate" @change="updateStartDate($event.value)"
												:max="eChartTransferMaxDate"
												:value="selectedStartDate" 
												@keyup="this.keyupdate('selectedStartDate')" :strictMode="true" id="selectedStartDate"/>
										</div>
									</div>
									<div class="contain_bx">
										<label for="text"><span class="cursor-text">To</span></label>
										<div class="calendar e-chart-clnder" @click="openDatePicker('end')">
											<ejs-datepicker class="text-center e-field form-control" ref="endDatePicker"
												:format="{ skeleton: 'full' }" :showClearButton="false"
												v-model="this.selectedREndDate" @change="updateEndDate($event.value)" 
												:max="eChartTransferMaxDate"
												:min="eChartTransferMinDate"
												:value="selectedEndDate"
												@keyup="this.keyupdate('selectedREndDate')" :strictMode="true" id="selectedREndDate"/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="hdng_con">
							<div class="heading">E-Chart Report Content</div>
							<span class="con_tx">Please select the content you would like the E-chart report to include.</span>
						</div>
						<div class="chart-report-check">
							<div class="d-flex justify-content-center selact_box_part">
								<div class="select_report_chart_first">
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.form.e_chart_transfer.is_demographic" checked></span>
										<label>Demographic</label>
									</div>
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.form.e_chart_transfer.is_encounter_notes" checked></span>
										<label>Encounter Notes</label>
									</div>
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.form.e_chart_transfer.is_files" checked></span>
										<label>Files</label>
									</div>
								</div>
								<div class="select_report_chart_second">
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.form.e_chart_transfer.is_measurements" checked></span>
										<label>Measurements</label>
									</div>
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.form.e_chart_transfer.is_medication" checked></span>
										<label>Medications</label>
									</div>
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.form.e_chart_transfer.is_other_infos" checked></span>
										<label>Other Infos</label>
									</div>
								</div>
								<div class="select_report_chart_thrd">
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.form.e_chart_transfer.is_preventions" checked></span>
										<label>Preventions</label>
									</div>
									<div class="address_line">
										<span><input class="form-check-input align-middle" type="checkbox" id="checkboxNoLabel" v-model="this.form.e_chart_transfer.is_screening" checked></span>
										<label>Screenings</label>
									</div>
								</div>
							</div>
						</div>
                        <div class="btn_part text-center">
                            <button class="comman_brdr_btn mx36" @click="closeEChartTransferPop"> Cancel </button>
                            <button class="comman_btn mx36" @click="printEchart">Print</button>
                            <button class="comman_btn mx36">Fax</button>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
import selectCheckBox from "@/components/base/selectCheckBox.vue";
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
import $ from "jquery";
import moment from "moment";

export default {
	props: [
		'echartElement'
	],
	data() {
		return {
			query_search: '',
			displayDiv: false,
			select_box_key: 0,
			isUnselectAll:false,
			boxElementToggleBtnText: 'Show All Elements',
			selectedStartDate: new Date(),
			selectedEndDate: new Date(),
			form: {
				e_chart_transfer : {
					is_all_time_range: true,
					from_date: null,
					to_date: null,
					is_demographic: true,
					is_encounter_notes: true,
					is_files: true,
					is_measurements: true,
					is_medication: true,
					is_other_infos: true,
					is_preventions: true,
					is_screening: true
				},
			},
			eChartTransferMaxDate: new Date(),
			eChartTransferMinDate: new Date(),
		};
	},
	watch: {
		'form.e_chart_transfer': {
			handler(newVal) {
				console.log('e_chart_transfer object updated:', newVal);
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		document.addEventListener('click', this.handleClickOutside);

	},
	methods: {
		keyupdate(obj){
			if($(`#${obj}-error`)){
				$(`#${obj}-error`).text("");
			}
			const inputValue = $(`#${obj}`).val();
			var sanitizedInput = inputValue.replace(/[^\d]/g, '');
			if (sanitizedInput.length > 8) {
				sanitizedInput = sanitizedInput.substring(0, 8);
			}
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function(_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});
			$(`#${obj}`).val(formattedDate);
		},
		closeEChartTransferPop() {
			window.$("#e-chart-transfer").modal("hide");
			this.selectedStartDate = new Date();
			this.selectedEndDate = new Date();
			this.form.e_chart_transfer.is_all_time_range = true;

			this.form.e_chart_transfer.from_date = null;
			this.form.e_chart_transfer.to_date = null;
			this.form.e_chart_transfer.is_demographic = true;
			this.form.e_chart_transfer.is_encounter_notes = true;
			this.form.e_chart_transfer.is_files = true;
			this.form.e_chart_transfer.is_measurements = true;
			this.form.e_chart_transfer.is_medication = true;
			this.form.e_chart_transfer.is_other_infos = true;
			this.form.e_chart_transfer.is_preventions = true;
			this.form.e_chart_transfer.is_screening = true;
		},
		formatDatePickerDate(date) {
			const d = new Date(date);
			const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
			return d.toLocaleDateString('en-US', options);
		},
		updateStartDate(value) {
			this.selectedStartDate = this.formatDatePickerDate(value);
			this.form.e_chart_transfer.from_date = moment(value).format('YYYY-MM-DD');
			this.validateDates()
		},
		updateEndDate(value) {
			this.selectedEndDate = this.formatDatePickerDate(value);
			this.form.e_chart_transfer.to_date = moment(value).format('YYYY-MM-DD');
			this.validateDates()
		},
		validateDates() {
			if (this.form.e_chart_transfer.from_date && this.form.e_chart_transfer.to_date) {
				this.eChartTransferMinDate = new Date(this.form.e_chart_transfer.from_date)
				if (new Date(this.form.e_chart_transfer.to_date) < new Date(this.form.e_chart_transfer.from_date)) {
					this.$filters.moshaToast("'To Date' must be greater than 'From Date'", "error");
					return false;
				}
			}

			return true
		},
		openDatePicker(type) {
			if (type === 'start') {
				this.$refs.startDatePicker.show();
			} else if (type === 'end') {
				this.$refs.endDatePicker.show();
			}
		},
		handleClickOutside(event) {
			const globalSearchElement = this.$refs.globalSearch;
			if (globalSearchElement && !globalSearchElement.contains(event.target)) {
				this.query_search = '';
			}
		},
		querySearch() {
			const query = encodeURIComponent(this.query_search.trim());
			if (query) {
				const searchUrl = process.env.VUE_APP_QUERY_SEARCH_URL + query + "&sp=0&searchType=PLAIN_TEXT&source=USER_INPUT&searchControl=TOP_PULLDOWN&autoComplete=false";
				window.open(searchUrl, '_blank');
			}
		},
		selected_provider(result) {
			this.$emit('clicked', result);
		}, 
		echarTransfer(){
			window.$("#e-chart-transfer").modal("show");
		},
		handleButtonClick() {
			this.$emit('buttonClick');
			this.boxElementToggleBtnText = (this.boxElementToggleBtnText == "Return To Selected")? "Show All Elements" : "Return To Selected";
		},
		printEchart(){
			if(this.form.e_chart_transfer.is_all_time_range === false)
			{
				if(this.form.e_chart_transfer.from_date === null)
				{
					this.$filters.moshaToast('Please select from date.', "error");
					return false;
				}
				if(this.form.e_chart_transfer.to_date === null)
				{
					this.$filters.moshaToast('Please select from date.', "error");
					return false;
				}
				
				if(!this.validateDates()) {
					return false;
				}
			}
			else
			{
				this.form.e_chart_transfer.from_date = null;
				this.form.e_chart_transfer.to_date = null;
			}

			if(this.form.e_chart_transfer.is_demographic === false && this.form.e_chart_transfer.is_encounter_notes === false &&
				this.form.e_chart_transfer.is_measurements === false && this.form.e_chart_transfer.is_files === false && 
				this.form.e_chart_transfer.is_medication === false && this.form.e_chart_transfer.is_other_infos === false && 
				this.form.e_chart_transfer.is_preventions === false && this.form.e_chart_transfer.is_screening === false)
			{
				this.$filters.moshaToast('Please select e-chart report content', "error");
				return false;
			}
			let formData = encodeURIComponent(JSON.stringify(this.form.e_chart_transfer));
			let url = this.$router.resolve({
				name: 'PatientEChartPrint', 
				params: { patient_id: this.$route.params.patient_id },
				query: { form_data: formData }
			}).href;

			window.open(url, '_blank');
		},
		handleAllTImeRangeChange(event){
			if (event.target.checked){
				this.form.e_chart_transfer.from_date = null;
				this.form.e_chart_transfer.to_date = null;
			} else {
				this.form.e_chart_transfer.from_date = moment().format("YYYY-MM-DD");
				this.form.e_chart_transfer.to_date = moment().format("YYYY-MM-DD");
			}
		}
	},
	components: {
		selectCheckBox,
		'ejs-datepicker': DatePickerComponent,
	}
};
</script>