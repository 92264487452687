<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 appointment_history allergies">
            <div class="a_header">
                <div>
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" class="form-control" placeholder="Search Screenings" v-model="form.keyword" @input="handleInput" @keydown.enter="screeningsSearch" ref="screeningsSearch">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters |  {{ this.filterList.length }}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" :selectedOpt="this.filterList" @refresh-data="fetchPatientScreenings"></CustomFilter>
                    </span>
                </div>
                <button class="comman_btn" @click="createScreening"> Add Screenings </button>
            </div>
            <div class="table-responsive consult-list">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="hover_unset w-50px"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'start_date' }"  @click="changeSort('start_date')">Date <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'screening_type' }"  @click="changeSort('screening_type')"> Screening Type <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" :class="{ 'active': this.sortBy == 'result' }"  @click="changeSort('result')" style="width:calc(var(--scale-ratio) * 150px);"> Status <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" class="hover_unset" style="width:calc(var(--scale-ratio) * 150px);"> Provider</th>
                            <th scope="col" class="hover_unset"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in this.screeningList" :key="index">
                            <td></td>
                            <td>{{ item.start_date }}</td>
                            <td class="text-wrap-unset">{{ item.screening_type }}</td>
                            <td>{{ item.result }}</td>
                            <td>{{ item.provider_name }}</td>
                            <td class="edit_delet_link"><span class="popup_link" @click="editScreening(item.id)">View</span><span class="popup_link" @click="deleteRecord(item.id)">Delete</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="modal fade" id="Immunization_view_model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl" style="width: calc(var(--scale-ratio) * 1190px);">
			<div class="modal-content">
				<div class="modal-body">
                    <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="cancelScreening"></button>
                    <div class="box">
						<h2 class="popup_title">{{this.immunizationLbl}}</h2>
                        <div class="col-7 m-auto">
                            <div class="d-flex justify-content-between readonly-form">
                                <div>
                                    
                                </div>
                                <div class="Selact_drodwn">
                                    <div class="dropdown-div" @click="toggleStatusList">
                                        <div class="form-control caption"> {{ (form.result != null) ?
                                            form.result : 'Status' }} <img src="/images/down-arrow-new.svg" class="img-fluid"></div>
                                        <div class="list mt-0" v-show="showStatusList">
                                            <div v-for="(item, index) in this.statusOpt" :key="index" class="item" @click="selectStatus(item)" v-memo="[item.value]">
                                                {{ item.title }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row custom-form readonly-form">
                                <div class="col-6">
                                    <div class="input_box">
                                        <input type="text" class="form-control form-control-lg" v-model="form.start_date" readonly>
                                        <label class="form-label">Date<small class="asterisksign">*</small> </label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input_box screeningprovider-dropdown">
                                        <CustomDropDown :options="mrpProviderDataSource" :initialValue="this.provider_dropdown_text"
                                            v-model="form.provider_id" @item-selected="handleItemSelected"
                                            fieldName="referring_practitioner_id" id="referring_practitioner_id">
                                        </CustomDropDown>
                                        <ValidationErrorMessageList :errors="v$.form.provider_id.$errors" />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input_box mb-10">
                                        <input type="text" class="form-control form-control-lg mb-0" required v-model="form.screening_type">
                                        <label class="form-label">Screening Type<small class="asterisksign">*</small> </label>
                                        <ValidationErrorMessageList :errors="v$.form.screening_type.$errors" />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="input_box input_date_picker _datepic">
                                        <ejs-datepicker class="e-field form-control form-control-lg" v-model="form.next_date" :showClearButton="false" format="yyyy-MM-dd" @change="onFieldChange('next_date')" @blur="onFieldChange('next_date')" placeholder="Next Screening Date" @keyup="this.keyupdate('next_date')" :strictMode="true" id="next_date" ref="next_date"></ejs-datepicker>
                                    </div>
                                </div>
                                <div class="text_area">
                                    <label  class="txt_label">Instructions</label>
                                    <textarea class="form-control" rows="3" v-model="form.instructions"></textarea>
                                </div>
                                <div class="text_area">
                                    <label  class="txt_label">Comments</label>
                                    <textarea class="form-control" rows="3" v-model="form.comments"></textarea>
                                </div>
                                <div class="mt-5 mb-4 text-center">
                                    <button class="comman_brdr_btn big_btn mx36" @click="cancelScreening"> Cancel </button>
                                    <button class="comman_btn big_btn mx36" @click="saveScreening"> {{ this.lblText }} <img src="images/loader.gif" v-if="loader" style="width: calc(var(--scale-ratio) * 18px);" /></button>
                                </div>
                            </div>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebar from '../sidebar.vue';
import useVuelidate from "@vuelidate/core";
import axios from "@/scripts/axios.js";
import CustomFilter from '../../../base/CustomFilter.vue';
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";
import ValidationErrorMessageList from '../../../base/ValidationErrorMessageList.vue';
import $ from "jquery";
import moment from "moment";
import CustomDropDown from '../../../base/formFields/CustomDropDown.vue';

export default ({
    setup: () => {
        return { 
            v$: useVuelidate()
        };
    },
    validations() {
        return this.$store.state.screening.validationRules;
    },
    data() {
        return {
            isShowCustomFilter:false,
            screeningList:[],
            filterList:[],
            filterOptions:[
                { value: "received_timerange", title: "Date" },
                { value: "screening_type", title: "Screening Type" },
                { value: "result", title: "Status" },
                { value: "provider_name", title: "Provider" },
            ],
            loader: false,
            showStatusList: false,
            statusOpt:[
                { value: "Pending", title: "Pending" },
                { value: "Normal", title: "Normal" },
                { value: "Abnormal", title: "Abnormal" },
                { value: "Others", title: "Others" },
            ],
            lblText:'Create',
            searchTimeout: null,
            mrpProviderDataSource: [],
            provider_dropdown_text: 'Select Provider',
            isSelected: false
        }
    },
    components: {
        sidebar,
        CustomFilter,
        'ejs-datepicker': DatePickerComponent,
        ValidationErrorMessageList,
        CustomDropDown
    },
    mounted() {
        this.resetModelForm()
        document.addEventListener('click', this.handleClickOutside);
        this.fetchPatientScreenings()
		var $this = this;
		$(document).on("click", '._datepic', function () {
			const inputField = $(this).find('input');
			const inputId = inputField.attr('id');
			$this.openDatePicker(inputId);
		});
        this.fetchProviderData()
    },
    methods:{
        handleInput() {
            // Clear the previous timer if it exists
            clearTimeout(this.searchTimeout);

            // Start a new timer that triggers after 2 seconds of idle time
            this.searchTimeout = setTimeout(() => {
                this.screeningsSearch();
            }, 1000); // 1-second idle time
        },
        openDatePicker(refName) {
			var schObj = document.querySelector(`#${refName}`).ej2_instances[0];
			schObj.show();
		},
		keyupdate(obj){
			if($(`#${obj}-error`)){
				$(`#${obj}-error`).text("");
			}
			const inputValue = $(`#${obj}`).val();
			var sanitizedInput = inputValue.replace(/[^\d]/g, '');
			if (sanitizedInput.length > 8) {
				sanitizedInput = sanitizedInput.substring(0, 8);
			}
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function(_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});
			$(`#${obj}`).val(formattedDate);
		},
        createScreening(){
            this.resetModelForm()
            this.immunizationLbl = 'Add New Screening'
            this.lblText = 'Create'
            this.form.provider_id = ''
            this.provider_dropdown_text = 'Select Provider'
            if(localStorage.getItem('authUserRole') === 'Doctor' || localStorage.getItem('authUserRole') === 'Nurse'){
                this.form.provider_id = localStorage.getItem('userId')
                this.provider_dropdown_text = localStorage.getItem('authUserName');
                this.isSelected = true
            }
            window.$("#Immunization_view_model").modal("show");
        },
        editScreening(id){
            this.resetModelForm()
            this.edit(id)
            this.lblText = 'Save'
            this.immunizationLbl = 'Edit Screening'
            this.form.provider_id = 0;
            this.provider_dropdown_text = 'Select Provider'
            window.$("#Immunization_view_model").modal("show");
        },
        fetchPatientScreenings(){
            this.$store.state.loader = true;
            this.screeningList = []
			axios.post("patient/screening/list", {
                'patient_id':this.$route.params.patient_id,
                'keyword' : this.form.keyword,
                advanceFilter:this.form.advanceFilter,
                sort_by: this.sortBy,
                sort_order:this.sortOrder,
            })
            .then((response) => {
                this.$store.state.loader = false;
                this.screeningList = response.data.data
            })
        },
        screeningsSearch(){
            // Clear the previous timer if it exists
            clearTimeout(this.searchTimeout);

            this.fetchPatientScreenings()
        },
        addFilter(){
            this.isShowCustomFilter = !this.isShowCustomFilter;
        },
        resetModelForm(){
            Object.assign(
                this.$store.state.screening.form,
				this.$store.state.screening.defaultFormData
			);
            
            this.v$.$reset();
            this.$store.state.screening.form.next_date=''
        },
        changeSort(fieldName){
            if (fieldName != this.sortBy) {
                this.sortOrder = 'desc'
            } else if (this.sortOrder == 'desc') {
                this.sortOrder = 'asc'
            } else{
                this.sortOrder = 'desc'
            }

            this.sortBy = fieldName

            this.fetchPatientScreenings()
        },
        deleteRecord(id) {
            this.$swal({
                title: 'Are you sure to DELETE this record?',
                text: '',
                icon: '',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                closeOnConfirm: false,
                closeOnCancel: false,
                reverseButtons: true, // This will swap the Confirm and Cancel buttons
                    customClass: {
                        container: 'my-swal-container',
                        popup: 'my-swal-popup delete-popup',
                        header: 'my-swal-header',
                        title: 'my-swal-title',
                        closeButton: 'my-swal-close-button',
                        icon: 'my-swal-icon',
                        image: 'my-swal-image',
                        content: 'my-swal-content',
                        input: 'my-swal-input',
                        actions: 'my-swal-actions',
                        confirmButton: 'my-swal-confirm-button',
                        cancelButton: 'my-swal-cancel-button',
                        footer: 'my-swal-footer'
                    }
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = "patient/screening/delete";
                    axios.post(url, {'id':id, 'patient_id':this.$route.params.patient_id})
                    .then((response) => {
                        this.loader = false;
                        if (response.status == 200) {
                            this.fetchPatientScreenings()
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }) 
                    // this.$swal('Deleted!', 'Your record has been deleted.', 'success');
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                // this.$swal('Cancelled', 'Your record is safe :)', 'error');
                }
            });
        },
        edit(id){
            const url = "patient/screening/retrieve";
                axios.post(url, { id: id })
                .then((response) => {
                    if (response.status == 200) {
                        this.$store.state.screening.form = response.data.data
                    }

                    if(response.data.data.provider_id){
                        this.form.provider_id = response.data.data.provider_id
                        this.provider_dropdown_text = response.data.data.provider_name
                    }
                })
        },
        cancelScreening(){
            window.$("#Immunization_view_model").modal("hide");
            const queryId = this.$route.query.id;
            if (queryId && Number(queryId) > 0) {
                this.$router.push({ name: 'PatientEChartScreeningList', params: {patient_id: this.$route.params.patient_id} });
            }
        },
        saveScreening(){
            this.v$.$validate();
            const $this = this
            if (!this.v$.$error) {
                this.form.patient_id = this.$route.params.patient_id
                this.form.is_selected = this.isSelected
                $this.loader = true
                let url
                if (this.$store.state.screening.form.id) {
                    url = "patient/screening/update";
                } else {
                    url = "patient/screening/store";
                }

                axios.post(url, this.form)
                    .then((response) => {
                    if (response.status == 200) {
                        this.loader = false
                        const queryId = this.$route.query.id;
                        if (queryId && Number(queryId) > 0) {
                            $this.cancelScreening()
                            this.$router.push({ name: 'PatientEChartScreeningList', params: {patient_id: this.$route.params.patient_id} });
                        } else {
                            setTimeout(function () {
                                $this.fetchPatientScreenings()
                                $this.$filters.moshaToast(response.data.message, "success")
                                $this.cancelScreening()
                            }, 400);
                        }
                    } else {
                        this.loader = false
                        this.$filters.moshaToast(response.data.message, "error")
                    }
                })

            }else {
					for (const [key] of Object.entries(this.v$.form)) {
						if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
							const myEl = document.getElementById(key);
							if (myEl) {
								this.$smoothScroll({
									scrollTo: myEl,
								});
								break;
							}
						}
					}
				}
        },
        toggleStatusList() {
			this.showStatusList = !this.showStatusList;
		},
        selectStatus(item){
            this.$store.state.screening.form.result = item.value
        },
        onFieldChange(obj) {
			const sanitizedInput = $(`#${obj}`).val();
            console.log(sanitizedInput)
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});

			// Get or create the error message element
			let errorElement = $(`#${obj}-error`);
			if (errorElement.length === 0) {
				errorElement = $(`<div id="${obj}-error" style="color: red;margin-top: 5px;"></div>`);
				$(`#${obj}`).parent().after(errorElement);
			}
			const isValidDate = (formattedDate != '') ? this.isValidDate(formattedDate) : false;
			if (isValidDate) {
				errorElement.text(''); // Clear error message if the date is valid
				this.form[obj] = moment(sanitizedInput).format('YYYY-MM-DD');
			} else {
				if (formattedDate != '') {
					errorElement.text('Invalid Date');
				}
				$(`#${obj}`).val("");
			}
        },
        isValidDate(dateString) {
			const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
			const date = new Date(year, month - 1, day);
			return date.getFullYear() === year && (month === 0 || date.getMonth() + 1 === month) && (day === 0 || date.getDate() === day);
		},
        handleCustomField(values){
            this.filterList = values
            let advanceFilter={}
            values.forEach(item => {
                let value = item.value
                if (item.value == 'Not Set') {
                    value = ''
                }
                advanceFilter[item.field] = value
            });
            this.form.advanceFilter = advanceFilter
        },
        handleClickOutside(event) {
            if (this.isShowCustomFilter && !event.target.closest('.comman_btn') && !event.target.closest('.search_drop_box') && !event.target.closest('.search') && event.target.closest('.a_header')) {
                this.fetchPatientScreenings()
                this.isShowCustomFilter = false;
            }

        },
        async fetchProviderData() {
			var $this = this
			axios.post("json_list/provider", { roleId: [2] })
				.then((response) => {
					const providerTempData = [];
					const details = response.data.data;
					$.each(details, function (key, detail) {
						if (!($this.$route.name == "PatientEChartConsultAdd" && detail.deleted_datetime)) {
							providerTempData.push({
								value: detail.user_id,
								title: detail.full_name,
							})
						}
					})
					this.mrpProviderDataSource = providerTempData
				})
		},
        handleItemSelected(item) {
          if(item.fieldName === 'referring_practitioner_id') {
            this.form.provider_id = item.item.value
            this.provider_dropdown_text = item.item.title
            this.isSelected = true
          }
        }

    },
    created(){
        this.$store.state.loader = true;
        const queryId = this.$route.query.id;
        if (queryId && Number(queryId) > 0) {
            setTimeout(() => {
                this.editScreening(Number(queryId))
            }, 500);
        }
    },
    computed: {
        form() {
			return this.$store.state.screening.form;
		}
    },
})
</script>
