<template>
	<div class="PatientChart_header">
		<div class="row align-items-center">
			<div :class="headerClass">
				<span class="logo me-2">{{ headerTitle }}</span>
				<img src="/images/person-male-icon.svg" class="patient_icon ms-4" v-if="this.$route.name === 'PatientCreate'">
			</div>
			<div :class="headerSubClass">
				<div class="d-flex align-items-center " :class="justifyClass">
					<div class="detail_list cursor-pointer"  v-if="this.isDemographicRoute()" @click="redirectMasterRecord">
						<img src="/images/person-acc-icon.svg" class="patient_icon"><label :class="{ 'text-decoration-underline': this.$route.name == 'PatientEChart'}">{{ $filters.strToUpperCase($store.state.patientHeaderData.last_name) }}, {{ $filters.strToUpperCase($store.state.patientHeaderData.first_name) }} </label><span class="ms-3"> {{ $store.state.patientHeaderData.dob }} ( {{ $filters.patientCalculatedAge($store.state.patientHeaderData.dob) }} )<label class="ms-3 cursor-text">{{ $store.state.patientHeaderData.gender }}</label>
						<label class="ms-3 cursor-text" v-if="this.$route.name == 'PatientEChart'">{{this.$filters.formatPhoneNumber($store.state.patientHeaderData.cell_phone)}}</label>
						</span>

					</div>
					<div v-if="this.$route.name == 'PatientEChartPrescriptionAdd'" class="prescriber_details d-flex align-items-center">
						<span class="prescriber-ttl">Prescriber</span>
						<img v-if="this.currentUserRole == 'Doctor'" src="/images/user-profile-02.svg" class="dr-profile-icon">
						<img v-else src="/images/user-profile-01.svg" class="dr-profile-icon">
						<p class="prescriber_name mb-0">{{ this.currentUserLastName }}, {{  this.currentUserFirstName  }}</p>
					</div>
					<div class="master-search-ptnt" style="min-height: calc(var(--scale-ratio) * 42px);">
						<div class="search d-inline-block" v-if="this.$route.name == 'PatientEChart' || this.$route.name == 'PatientChart' || this.$route.name == 'PatientAppointmentHistory'">
							<img class="serch_icon" src="/images/search-icon.svg" alt="">
							<input type="text" @keyup="patientSearch" class="form-control" placeholder="Search Patient" v-model="patient_search" ref="globalSearch">
							<div class="search_drop_box head-search-patient" v-if="patientList.length > 0">
								<div class="patientList">
									<slot v-for="(item, index) in patientList" :key="index">
										<div class="con_drop_line" >
											<div class="row px-2 align-items-center">
												<div class="col-md-6">
													<div class="man_haeding">{{ item.last_name }}, {{ item.first_name }}
														<span>({{ item.gender }})</span>
													</div>
													<ul>
														<li>
															DOB: {{ item.dob }}
														</li>
														<li>
															HIN: {{ item.health_insurance_no }}
														</li>
														<li>
															Tel: {{ item.cell_phone }}
														</li>
													</ul>
												</div>
												<div class="col-md-6 px-1 text-end">
													<ol class="">
														<li class="prevent">
															<router-link :to="{ name: 'PatientChart', params: { patient_id: item.patient_id, } }" target="_blank">M</router-link>
														</li>
														<li class="prevent">
															<router-link :to="{ name: 'PatientEChart', params: { patient_id: item.patient_id, } }" target="_blank">E</router-link>
														</li>
														<li class="prevent">
															<router-link :to="{ name: 'PatientEChartPrescriptionList', params: { patient_id: item.patient_id, } }" target="_blank">Rx</router-link>
														</li>
														<li class="prevent" @click="openPrintHeaderLabel(item.patient_id)">
															<a href="javascript:void(0)" class="">Label</a>
														</li>
													</ol>
												</div>
											</div>
										</div>
									</slot>
								</div>
								<div class="create_grpc border-top"><router-link target="_blank" to="/patient/create">CREATE DEMOGRAPHIC</router-link></div>
							</div>
						</div>
						<!-- <span @click="cancelBtn" class="close_sign">
							<img src="/images/close_sign.svg">
						</span> -->
						<!-- <i class="fa-solid fa-xmark" @click="cancelBtn"></i> -->
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="print-label-header-model" tabindex="-1" style="display: none;" aria-hidden="true" ref="printLabelModel">
		<div class="modal-dialog modal-dialog-centered modal-xl opacity-0">
			<div class="modal-content">
				<div class="modal-body">					
					<div class="box position-relative" v-if="print_label_user_info">
						<div class="main_hd" v-if="print_label_user_info.last_name || print_label_user_info.first_name">
							{{ print_label_user_info.last_name.toUpperCase() }}  {{ print_label_user_info.first_name.toUpperCase() }}
						</div>
						<div class="d-flex align-items-center" v-if="print_label_user_info.health_insurance_no || print_label_user_info.health_card_ver || print_label_user_info.gender || print_label_user_info.dob">
							<span class="bold_title m-0" v-if="print_label_user_info.health_insurance_no || print_label_user_info.health_card_ver">HEALTH #</span>
							<span class="bold_line" v-if="print_label_user_info.health_insurance_no || print_label_user_info.health_card_ver">|</span>
							<span class="con_detail" v-if="print_label_user_info.health_insurance_no || print_label_user_info.health_card_ver">
								{{ print_label_user_info.health_insurance_no }} {{ print_label_user_info.health_card_ver }}
							</span> 
							<span class="bold_title" v-if="print_label_user_info.gender">GENDER</span>
							<span class="bold_line" v-if="print_label_user_info.gender">|</span>  
							<span class="con_detail" v-if="print_label_user_info.gender">
								<template v-if="print_label_user_info.gender === 'M'">Male</template>
								<template v-else-if="print_label_user_info.gender === 'F'">Female</template>
								<template v-else-if="print_label_user_info.gender === 'O'">Other</template>
								<template v-else-if="print_label_user_info.gender === 'T'">Trans</template>
							</span> 
							<span class="bold_title" v-if="print_label_user_info.dob">DOB</span>
							<span class="bold_line" v-if="print_label_user_info.dob">|</span>
							<span class="con_detail" v-if="print_label_user_info.dob">{{ print_label_user_info.dob }}</span>
						</div>
						<div class="mt-5">
							<div class="con_detail mb-1" v-if="print_label_user_info.residential_address">{{ print_label_user_info.residential_address }}</div>
							<div class="con_detail mb-1" v-if="print_label_user_info.residential_city || print_label_user_info.residential_province_name || print_label_user_info.residential_zip">
								{{ $filters.capitalizeFirstWordFirstLetter(print_label_user_info.residential_city) }}<span v-if="print_label_user_info.residential_city && (print_label_user_info.residential_province_name || print_label_user_info.residential_zip)">, </span>{{ $filters.capitalizeFirstWordFirstLetter(print_label_user_info.residential_province_name) }} {{ print_label_user_info.residential_zip }}
							</div>
							<div class="bold_title m-0" v-if="print_label_user_info.cell_phone"> TEL <span class="con_detail">{{ print_label_user_info.cell_phone }}</span></div>
						</div>
					</div>
					<img src="/images/gray_logo.svg" class="img-fluid watermark_logo">
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import axios from "@/scripts/axios.js";

export default {
	emits: ['trigger-print-label-modal'], // Declare the emitted event
	components: {
	},
	data() {
		return {
			currentPatient:[],
			patientList:[],
			patient_search: [],
			currentUserFirstName: this.getCurrrentUserFirstName(),
			currentUserLastName: this.getCurrrentUserLastName(),
			currentUserRole: this.getCurrrentUserRole(),
			print_label_user_info: null,
		};
	},
	props: {
		headerTitle: {
			type: String,
			required: true
		},
	},
	validations() { },
	created() { },
	methods: {
		redirectMasterRecord() {
			this.$router.push(
					'/patient/chart/' + this.$route.params.patient_id
				);
		},
		cancelBtn(){
			if (['PatientEChart', 'PatientCreate', 'PatientChart', 'PatientEdit', 'PatientAppointmentHistory', 'PatientEnrollmentHistory'].includes(this.$route.name)) {
				this.$router.push(
					'/'
				);
			}
			else if(this.$route.name == 'PatientEChartConsultAdd')
			{
				this.$router.push(
					'/patient/e-chart/' + this.$route.params.patient_id + '/consultation/consult-list'
				);
			}
			else
			{
				this.$router.push(
					'/patient/e-chart/' + this.$route.params.patient_id
				);
			}
		},
		patientSearch() {
			if (this.patient_search.length > 2) {
				axios.post("json_autocomplete/patient-search", { search_text: this.patient_search })
					.then((response) => {
						this.patientList = response.data.data;
					})
			}
			if(this.patient_search.length == 0){
				this.patientList = [];
			}
		},
		handleClickOutside(event) {
			const globalSearchElement = this.$refs.globalSearch;
			if (globalSearchElement && !globalSearchElement.contains(event.target)) {
				this.patient_search = '';
				this.patientList = [];
			}
			
		},
		edit(){
			axios.post("patient/retrieve", { 'id': this.$route.params.patient_id })
				.then((response) => {
					if (response.data.status === 200) {
						let detail = response.data.data;

						this.currentPatient = detail;

					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},
		isDemographicRoute() {
			return !(
				this.$route.name === "PatientCreate" ||
				this.$route.name === "templateMasterList" 
			)
		},
		getCurrrentUserFirstName() {
			if(localStorage.getItem('userFirstName') != null) {
				return localStorage.getItem('userFirstName').toUpperCase();
			}
        },
		getCurrrentUserLastName() {
			if(localStorage.getItem('userLastName') != null) {
				return localStorage.getItem('userLastName').toUpperCase();
			}
		},
		getCurrrentUserRole() {
			if(localStorage.getItem('authUserRole') != null) {
				return localStorage.getItem('authUserRole');
			}
		},
		openPrintHeaderLabel(patientId) {
			this.$emit('trigger-print-label-modal', patientId);
		},
	},
	mounted(){
		document.addEventListener('click', this.handleClickOutside);
		if (this.$route.name === "PatientEdit") {
			this.edit();
		}
	},
	computed: {
		justifyClass() {
			return {
				'justify-content-between': this.isDemographicRoute(),
				'justify-content-end': !this.isDemographicRoute()
			};
		},
		headerClass(){
			return {
				'col-xxl-2 col-md-3 col-sm-3 col-3': this.isDemographicRoute(),
				'col-md-4 col-sm-4 col-4': !this.isDemographicRoute()
			};

		},
		headerSubClass(){
			return {
				'col-xxl-10 col-md-9 col-sm-9 col-9': this.isDemographicRoute(),
				'col-md-8 col-sm-8 col-8': !this.isDemographicRoute()
			};

		}
	}
};
</script>