<template>
	<div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 left_side_bar px-0" v-once>
		<div class="sticky-scroll-box">
			<ul class="side-bar-menu" data-widget="tree">
				<li class="mt-0" v-if='this.$route.name === "PatientCreate"' :class="{ 'active': (this.$route.name === 'PatientCreate') }">
					<a href="javascript::void(0)">Basic Information <img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid ms-2"></a>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'PatientChart'">
				</li>
				<li class="mt-0" v-if='this.$route.name !== "PatientCreate"' :class="{ 'active': (this.$route.name === 'PatientChart' || this.$route.name === 'PatientEdit') }">
					<!-- <a href="javascript::void(0)">Basic Information <img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid ms-2"></a> -->
					<router-link :to="{ name: 'PatientChart',params: { patient_id: $route.params.patient_id,  }}"> Basic Information </router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'PatientChart'">
				</li>
				<li v-if='this.$route.name !== "PatientCreate"' :class="{ 'active': this.$route.name === 'PatientAppointmentHistory' }">
					<router-link :to="{ name: 'PatientAppointmentHistory',params: { patient_id: $route.params.patient_id,  }}"> Appointment History</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'PatientAppointmentHistory'">
				</li>
				<li v-if='this.$route.name !== "PatientCreate"' :class="{ 'active': this.$route.name === 'PatientEnrollmentHistory' }">
					<router-link :to="{ name: 'PatientEnrollmentHistory',params: { patient_id: $route.params.patient_id,  }}"> Enrollment History</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'PatientEnrollmentHistory'">
				</li>
			</ul>
			<ul class="side-bar-menu" data-widget="tree" v-if='this.$route.name !== "PatientCreate"'>
				<li>
					<router-link target="_blank" :to='{ name: "InvoiceMasterCreate",params: { patient_id: $route.params.patient_id,  } }'>Billing Module</router-link>
				</li>
			</ul>
			<ul class="side-bar-menu" data-widget="tree" v-if='this.$route.name !== "PatientCreate"'>
				<li>
					<router-link target="_blank" :to='{ name: "PatientEChart" }'>E-Chart Module</router-link>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
// import $ from "jquery";

export default {
	data() {
		return {
			
		};
	},
	watch: {
		
	},
	mounted() {},
	methods: {
		changeComp(routerName) {
			if(routerName == 'PatientEChart'){
				const { protocol, hostname, port } = window.location;
				
				// Construct the base URL using the parts obtained above
				let baseUrl = `${protocol}//${hostname}`;
				
				// Add the port to the base URL if it's not the default for the protocol
				if (port && !['80', '443'].includes(port)) {
					baseUrl += `:${port}`;
				}

				baseUrl += `/#/patient/e-chart/${this.$route.params.unique_id}`

				const newWindow = window.open(baseUrl, '_myechartwin','height=600,width=900');
				if (newWindow) {
					newWindow.focus();
				}
				
			}else{
				return routerName;
			}
		},	
		openWindow(moduleName, action='') {
			const { protocol, hostname, port } = window.location;

			// Construct the base URL using the parts obtained above
			let baseUrl = `${protocol}//${hostname}`;

			// Add the port to the base URL if it's not the default for the protocol
			if (port && !['80', '443'].includes(port)) {
				baseUrl += `:${port}`;
			}

			baseUrl += `/#/patient/${moduleName}/${this.$route.params.unique_id}`
			if (action != '') {
				baseUrl += `/${action}`
			}

			const newWindow = window.open(baseUrl, `_my${moduleName}win`, 'height=600,width=1000');
			if (newWindow) {
				newWindow.focus();
			}
		},	
	},
};
</script>